import logger from './logger';

console.error = (message?: any, ...optionalParams: any[]) => {
  logger.error(message, optionalParams);
};

console.info = (message?: any, ...optionalParams: any[]) => {
  logger.info(message, optionalParams);
};

console.debug = (message?: any, ...optionalParams: any[]) => {
  logger.debug(message, optionalParams);
};

console.trace = (message?: any, ...optionalParams: any[]) => {
  logger.trace(message, optionalParams);
};
console.log = (message?: any, ...optionalParams: any[]) => {
  logger.info(message, optionalParams);
};
  
console.warn = (message?: any, ...optionalParams: any[]) => {
  logger.warn(message, optionalParams);
};
  