import Router from 'next/router';
import configJSON from 'config.json';
import { camelizeKeys } from '@zoocasa/node-kit/objects/transform-keys';
import endpoint, { apiHeaders } from 'utils/endpoint';
import { Point, getListingById } from 'data/listing';
import { dasherize } from '@zoocasa/node-kit/strings/dasherize';
import getTargetedUrl from 'components/dynamic-page/new-area-listings-page/targeted-url-helper';
import { captureLastSearch } from 'pages/api/last-search/lastSearchApiClient';
import { formatQueryIntoURLQueryParams } from 'utils/listing-query-helper';
import defaultListingParams from 'contexts/preferences/listing-params/defaults';
import { getPositionFromAddress } from 'utils/apple-maps';
import { findLocationByPlaceId } from 'utils/google-maps/geoLocator';
import { trackEvent } from 'utils/google-tag-manager';
import determineZoomLevel from 'utils/determine-zoom-level';
import { SEARCH_PREDICTION_SOURCE_GOOGLE } from 'utils/google-maps/types';
import { HttpRequestMethodTypes } from 'types';
import { Position } from '@zoocasa/node-kit/map/types';
import { ThemeNames } from 'types/themes';
import { getGoSearchHost, isServerSide } from 'utils/host-config';

import type ListingParams from 'contexts/preferences/listing-params';

export type MapType = { changeLocation(location: Position, zoom: number): void };

export default class SearchPrediction {
  id: string;
  locationId: string | null;
  description: string;
  group: 'listings' | 'locations' | 'schools' | 'buildings';
  label: string | null;
  matchedSubstrings: {
      length: number;
      offset: number;
  }[];
  data: {
      position: Point;
  } | null;
  source: string | null;
  identifier: number | null;

  constructor(searchPrediction: Record<string, unknown>) {
    const camelizedSearchPrediction = camelizeKeys(searchPrediction);
    const attributes = camelizedSearchPrediction.attributes as Record<string, unknown>;
    const relationships = camelizedSearchPrediction.relationships as Record<
      string,
      unknown
    >;
    const formattedSearchPrediction = {
      ...attributes,
      ...relationships,
      id: camelizedSearchPrediction.id,
    } as SearchPrediction;
    Object.assign(this, formattedSearchPrediction);
  }

  get path() {
    const { group } = this;
    if (group === 'locations') {
      const [longitude, latitude] = this.data?.position ? this.data!.position.coordinates : [0, 0];
      return '/search?' + formatQueryIntoURLQueryParams({ latitude, longitude });
    } else {
      const typeOfPrediction = group.replace(`${group}-`, '').replace(/s$/, '');
      const slug = this.id.replace(/\s$/, '').replace(`${typeOfPrediction}-`, '');
      return `/${typeOfPrediction}/${slug}`;
    }
  }

  get location() {
    const defaultPosition = [defaultListingParams.filter.longitude, defaultListingParams.filter.latitude];
    return endpoint<Record<string, any>>(`/services/api/v3/locations/${this.id.replace('location-', '')}`)
      .then(({ data }) => {
        if (data) {
          return data.attributes.position.coordinates;
        } else {
          return defaultPosition;
        }
      }).catch(() => {
        return getPositionFromAddress(this.id.replace('location-', '')).then(data => {
          if (data.results[0]) {
            return [data.results[0].coordinate.longitude, data.results[0].coordinate.latitude];
          } else {
            return defaultPosition;
          }
        });
      });
  }

  get slug() {
    let slug = '';
    const { group, id } = this;
    if (group === 'locations') {
      slug = getLocationSlugFromId(id);
    }
    return slug;
  }

  setIdentifier = (identifier: number) => {
    this.identifier = identifier;
  };

  transitionToPath = async (searchPageQueryParams: ListingParams, sourceTheme: ThemeNames, map?: MapType, user?: any) => {
    const { group, path, source } = this;
    trackEvent(group, 'search-results');
    if (group === 'locations' && searchPageQueryParams) { // if search result is a location (i.e. cities)
      // Generating the lat and long for this location, and it will be used to direct user to map page (if applicable)
      let latitude = defaultListingParams.filter.latitude;
      let longitude = defaultListingParams.filter.longitude;
      const zoom = determineZoomLevel(self);
      if (source === 'apple') {
        longitude = this.data!.position.coordinates[0];
        latitude = this.data!.position.coordinates[1];
      } else if (source === SEARCH_PREDICTION_SOURCE_GOOGLE) {
        let locationId;
        if (this.locationId) {
          locationId = this.locationId;
        } else {
          locationId = this.id;
        }
        const location = await findLocationByPlaceId(locationId);
        longitude = location?.longitude || 0;
        latitude = location?.latitude || 0;
      } else {
        const { location } = this;
        const locationData = await location;
        longitude = locationData[0];
        latitude = locationData[1];
      }

      // Setting the slug for this location, and it will be used to direct user to area page (if applicable)
      if (this.slug) {
        searchPageQueryParams.setSlug(this.slug);
      } else {
        searchPageQueryParams.setSlug('');
      }
      if (this.description) {
        searchPageQueryParams.setAreaName(this.description);
      }
      searchPageQueryParams.setZoom(zoom);
      searchPageQueryParams.setLatitude(latitude);
      searchPageQueryParams.setLongitude(longitude);

      // Determine which page user should go to
      if (map) { // if on map page, stay in map page
        // Update the listingParams with the new coordinates and zoom, and save them to the user's last search
        if (user?.id && searchPageQueryParams) {
          captureLastSearch({ userId: user.id, lastSearch: searchPageQueryParams, sourceTheme: sourceTheme });
        }
        map.changeLocation({ lng: longitude, lat: latitude }, zoom);
        return;
      } else { // if on other pages, determine if we should go to an area page or map page
        if (source !== 'apple' && this.slug) { // if location has a slug go to area page with existing filters
          let location = this.slug;
          let subLocation = '';
          if (this.label === 'neighbourhood') {
            const locationArr = this.description.split(', ');
            subLocation = '/' + dasherize(locationArr[0]);
            location = dasherize((locationArr[1] + ' ' + locationArr[2]).toLowerCase());
          }
          const areaPageUrl = `${window.location.origin}/${location}-real-estate${subLocation}`;
          const areaPageUrlWithFilters = getTargetedUrl({ url: areaPageUrl, filters: searchPageQueryParams.filter, sort: searchPageQueryParams.sort, pageNumber: 1 });
          if (user?.id && searchPageQueryParams) {
            captureLastSearch({ userId: user.id, lastSearch: searchPageQueryParams, sourceTheme: sourceTheme });
          }
          Router.push(areaPageUrlWithFilters);
        } else { // if location does not have a slug go to search page with existing filters
          if (user?.id && searchPageQueryParams) {
            captureLastSearch({ userId: user.id, lastSearch: searchPageQueryParams, sourceTheme: sourceTheme });
          }
          Router.push('/search?' + formatQueryIntoURLQueryParams(searchPageQueryParams.filter) + '&sort=' + searchPageQueryParams.sort);
        }
      }
    } else if (group === 'listings') { // if search result is a listing
      const listingId = this.id.split('-')[1];
      const listing = await getListingById(listingId);
      Router.push(listing.addressPathWithFallback);
    } else if (group === 'schools') { // if search result is a school
      window.location.href = `/school/${this.id.replace('school-', '')}`;
    } else if (group === 'buildings') { // if search result is a building
      Router.push(path);
    }
  };
}

export async function getSearchPredictions(params: Record<string, any>, useNewSearch = false) {
  try {

    let searchPredictionsUrl = `${getGoSearchHost(isServerSide())}/api/search-predictions`;
    let headers = {};

    if (!useNewSearch) { // Fallback to use client app search instead of go-search
      searchPredictionsUrl = `${isServerSide() ? configJSON.clientAppInternalHost : configJSON.host}/services/api/v3/search-predictions`;
      headers = apiHeaders();
    }

    const { data } = await endpoint<{ data: Record<string, unknown>[] }>(searchPredictionsUrl, HttpRequestMethodTypes.GET, params, headers);
    return data.map(d => new SearchPrediction(d));
  } catch {
    return null;
  }
}

export function createSearchPredictionModel(properties: SearchPrediction) {
  let data = null;
  if (properties.data) {
    data = { position: { ...properties.data.position, coordinates: [properties.data.position.coordinates[0], properties.data.position.coordinates[1]]}};
  }
  let matchedSubstrings: { length: number; offset: number }[] = [];
  if (properties.matchedSubstrings && properties.matchedSubstrings[0]) {
    matchedSubstrings = [{ length: properties.matchedSubstrings[0].length, offset: properties.matchedSubstrings[0].offset }];
  }

  return new SearchPrediction({
    attributes: {
      ...properties,
      data,
      matchedSubstrings,
    },
    id: properties.id,
  });
}

export function getLocationSlugFromId(id: string) { // This can also determine whether this location has an area page
  return id.split('location-')[1];
}

export function checkLocationHasAreaPageWithId(id: string) {
  return !!getLocationSlugFromId(id);
}
