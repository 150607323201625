
export const PRICE_SORT = 'price';
export const PRICE_DESCENDING_SORT = '-price';
export const DATE_SORT = 'date';
export const DATE_DESCENDING_SORT = '-date';
export const BEDROOMS_SORT = 'bedrooms';
export const BEDROOMS_DESCENDING_SORT = '-bedrooms';
export const BATHROOMS_SORT = 'bathrooms';
export const BATHROOMS_DESCENDING_SORT = '-bathrooms';
export const SORT_KEYS: readonly string[] = [PRICE_SORT, PRICE_DESCENDING_SORT, DATE_SORT, DATE_DESCENDING_SORT, BEDROOMS_SORT, BEDROOMS_DESCENDING_SORT, BATHROOMS_SORT, BATHROOMS_DESCENDING_SORT];
export type Sort = typeof PRICE_SORT | typeof PRICE_DESCENDING_SORT | typeof DATE_SORT | typeof DATE_DESCENDING_SORT | typeof BEDROOMS_SORT | typeof BEDROOMS_DESCENDING_SORT | typeof BATHROOMS_SORT | typeof BATHROOMS_DESCENDING_SORT;

export const AVAILABLE_STATUS = 'available';
export const NOT_AVAILABLE_STATUS = 'not-available';
export const NOT_AVAILABLE_SOLD_STATUS = 'not-available-sold';
export const NOT_AVAILABLE_OTHER_STATUS = 'not-available-other';
export const INACTIVE_STATUS = 'inactive';
export const EXPIRED_STATUS = 'expired';
export const STATUS_KEYS: readonly string[] = [AVAILABLE_STATUS, NOT_AVAILABLE_STATUS, NOT_AVAILABLE_SOLD_STATUS, NOT_AVAILABLE_OTHER_STATUS];
export type Status = typeof AVAILABLE_STATUS | typeof NOT_AVAILABLE_STATUS | typeof NOT_AVAILABLE_SOLD_STATUS | typeof NOT_AVAILABLE_OTHER_STATUS;

export type HomeTypeFilter = Record<Exclude<HomeType, 'house'>, boolean>;

export const ANY_LOCKER_VALUE = 'any';
export const YES_LOCKER_VALUE = 'yes';
export const NO_LOCKER_VALUE = 'no';
export const LOCKER_VALUES: readonly string[] = [ANY_LOCKER_VALUE, YES_LOCKER_VALUE, NO_LOCKER_VALUE];
export type Locker = typeof ANY_LOCKER_VALUE | typeof YES_LOCKER_VALUE | typeof NO_LOCKER_VALUE;

export type Bedroom = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type HomeType = 'house' | 'houseDetached' | 'houseSemidetached' | 'houseAttached' | 'townhouse' | 'condo';

export type FlattenFilter = {
  rental: boolean;
  status: Status;
  slug: string;
  latitude: number;
  longitude: number;
  zoom: number;
  houseDetached: boolean;
  houseSemidetached: boolean;
  houseAttached: boolean;
  townhouse: boolean;
  condo: boolean;
  priceMin: number | null;
  priceMax: number | null;
  bedrooms: string;
  sqftMin: number | null;
  sqftMax: number | null;
  listedSince: string | null;
  listedTo: string | null;
  bathrooms: string;
  parkingSpaces: string;
  openHouse: boolean;
  garage: boolean;
  pool: boolean;
  fireplace: boolean;
  waterfront: boolean;
  singleFamily: boolean;
  basementApartment: boolean;
  duplex: boolean;
  triplex: boolean;
  'fourplex+': boolean;
  areaName: string;
  boundary: string | null;
  providerId: string | null;
};

export type FilterKeys = keyof Filter;

export type Filter = {
  rental: boolean;
  status: Status;
  slug: string;
  latitude: number;
  longitude: number;
  zoom: number;
  homeType: HomeTypeFilter;
  priceMin: number | null;
  priceMax: number | null;
  bedrooms: string;
  sqftMin: number | null;
  sqftMax: number | null;
  listedSince: string | null;
  listedTo: string | null;
  bathrooms: string;
  parkingSpaces: string;
  openHouse: boolean;
  garage: boolean;
  pool: boolean;
  fireplace: boolean;
  waterfront: boolean;
  additional: {
    house: {
      singleFamily: boolean;
      basementApartment: boolean;
      duplex: boolean;
      triplex: boolean;
      'fourplex+': boolean;
    };
    condoOrTownhouse: {
      locker: Locker;
      maintenanceFee: number | null;
    };
  };
  areaName: string;
  boundary: string | null;
  providerId: string | null;
};

export type Page = {
  number: number;
  size: number;
};

export type ListingParams = {
  page?: Page;
  sort: Sort;
  filter: Filter;
};
