import { IncomingMessage } from 'http';

class Cookies {
  cookies: Record<string, string> = {};

  constructor(req: IncomingMessage) {
    req.headers.cookie?.split(/\s*;\s*/)?.forEach(cookie => {
      const [key, value] = cookie.split(/\s*=\s*/);
      this.cookies[key] = value;
    });
  }

  get(name: string): string | undefined {
    return this.cookies[name];
  }
}

export function getObjectFromRequestCookie<T = Record<string, string>>(name: string, req?: IncomingMessage): T | undefined { 
  if (req) {
    const cookies = new Cookies(req);
    const cookie = cookies.get(name);

    if (cookie) {
      return getJSON<T>(cookie);
    }
  }
  return undefined;
}

export function getStringFromRequestCookie(name: string, req?: IncomingMessage): string | undefined {
  if (req) {
    const cookies = new Cookies(req);
    return cookies.get(name);
  }
  return undefined;
}

export function getJSON<T = string>(cookie?: string): T | undefined {
  if (cookie) {
    try {
      return JSON.parse(decodeURIComponent(cookie)) as T;
    } catch (e){
      console.error(e);
      return undefined;
    }
  }
  return undefined;
}