export const listingComponentHeights = {
  marketStats: {
    desktop: '635px',
    mobile: '1165px',
    noData: '0px',
    modal: '1490px',
  },
};

export const defaultHomeType = {
  houseAttached: true,
  houseDetached: true,
  houseSemidetached: true,
  townhouse: true,
  condo: true,
};
