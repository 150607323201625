export const testIds = {
  test: 'test',
  advancedSearch: 'advancedSearch',
  article: 'blogArticle',
  availableHomeListings: 'availableHomeListings',
  homesForSaleLocation: 'homesForSaleLocation',
  homesForSaleLink: 'homesForSale',
  soldPricesButtonLink: 'forSoldButton',
  homesForSaleMapView: 'mapViewButton',
  moreDataButton: 'moreDataButton',
  brushStroke1: 'brushStroke1',
  brushStroke2: 'brushStroke2',
  brushStroke3: 'brushStroke3',
  chartGradient: 'chartGradient',
  ctaCard: 'ctaCard',
  ctaButton: 'ctaButton',
  featuredHomesListings: 'featuredHomesListings',
  featuredHomesLink: 'featuredHomes',
  featuredHomesMoreListings: 'moreListingsButton',
  headerSearch: 'headerSearch',
  homeCTALink: 'homeCTALink',
  homeKeywords: 'homeKeyWords',
  internalLink: 'internalLink',
  internalLinkContent: 'internalLinkContent',
  internalLinksContainer: 'internalLinksContainer',
  loadingSkeleton: 'loadingSkeleton',
  marketInsightsWidget: 'marketInsightsWidget',
  marketInsightsLink: 'marketInsightsLink',
  marketInsightsMoreNews: 'moreNewsButton',
  soldHomeListings: 'soldHomeListings',
  carouselNext: 'carouselNext',
  swiperCarouselComponent: 'swiper-carousel-component',
  modalInner: 'modalInner',
  listingGridBreadcrumbs: 'listingGridBreadcrumbs',
  defaultBreadcrumbs: 'defaultBreadcrumbs',
  breadcrumbLink: 'BreadcrumbLink',
  pageNavigation: 'page-navigation',
  emberNext: 'ember-next',
  formWarning: 'formWarning',
  findByKeyword: 'findByKeyword',
  expandIcon: 'expandIcon',
  schoolPin: 'schoolPin',
  userIcon: 'userIcon',
  mobileuser: 'mobileuser',
  coverImage: 'coverImage',
  imageDescription: 'image-description',
  loadingSpinner: 'loading-spinner',
  ratehubCalculator: 'ratehubCalculator',
  ratehubLogo: 'ratehubLogo',
  reviewsSellPage: 'reviewsSellPage',
  suggestedLocationsDropdown: 'suggestedLocationsDropdown',
  dropdownSection: 'DropdownSection',
  mortgageCalculatorHeading: 'mortgageCalculatorHeading',
  mortgageCalculatorDescription: 'mortgageCalculatorDescription',
  calculateMonthlyPaymentHeader: 'calculateMonthlyPaymentHeader',
  connectWithAgentModal: 'connect-with-agent',
  connectWithAgentCloseButton: 'connect-with-agentCloseButton',
  connectWithAgentSubmit: 'connectWithAgentSubmit',
  speakWithExpertButton: 'speakWithExpertButton',
  buyRadioButtonOption: 'BuyRadioButtonOption',
  sellRadioButtonOption: 'SellRadioButtonOption',
  buyAndSellRadioButtonOption: 'Buy&SellRadioButtonOption',
  sizeKeyFacts: 'SizeKeyFacts', // these IDs (suffix: KeyFacts) are dynamically generated and won't be found in global search
  maintenanceFeesKeyFacts: 'MaintenanceFeesKeyFacts',
  listingGrid: 'listing-grid',
  itsoVowAgreementModal: 'itsoVowAgreementModal',
  itsoVowAgreementAcceptButton: 'itsoVowAgreementAcceptButton',
  homeWorthButton: 'homeWorthButton',
  appBannerNotNow: 'appBannerNotNow',
  filterGroup: 'FilterGroup',
  radioButtonOption: 'RadioButtonOption',
  areaGuides: 'areaGuides',
  cityGuide: 'cityGuide',
  infraGuide: 'infraGuide',
  taxGuide: 'taxGuide',
  testField: 'test-field',
  toggle: 'toggle',
  closeButton: 'CloseButton',
  emailAlertRadioButton: 'emailAlertRadioButton',
  buyingGuide: 'buyingGuide',
  notificationBannerClose: 'notification-banner-close',
  menuButton: 'menuButton',
  menuDropdownOption: 'MenuDropdownOption',
  addMoreCard: 'AddMoreCard',
} as const;

export const profileUpdateIds = {
  fullNameEditButton: 'UpdateFullNameHeading',
  emailEditButton: 'UpdateEmailHeading',
  passwordEditButton: 'UpdatePasswordHeading',
  phoneEditButton: 'UpdatePhoneHeading',
  // Details
  updateProfileFirstName: 'updateProfileFirstName',
  updateProfileLastName: 'updateProfileLastName',
  updateNameButton: 'updateNameButton',
  updateProfilePhone: 'updateProfilePhone',
  updatePhoneButton: 'updatePhoneButton',
  updateProfileNewslettersCheckbox: 'updateProfileNewslettersCheckbox',
  // Email Update
  updateEmailNew: 'updateEmailNew',
  updateEmailButton: 'updateEmailButton',
} as const;

export const loginRegistrationIds = {
  loginModal: 'login-registration',
  closeButton: 'login-registrationCloseButton',
  loginRegistrationModal: 'loginRegistrationModal',
  backButton: 'backButton',
  changeEmailLink: 'changeEmail',
  loginRegistrationEmailTextField: 'loginRegistrationEmailTextField',
  passwordLoginModal: 'passwordLoginModal',
  forgotPassword: 'forgotPassword',
  signInButton: 'signInButton',
  createAccountFirstName: 'createAccountFirstName',
  createAccountLastName: 'createAccountLastName',
  createAccountPhone: 'createAccountPhone',
  createAccountPassword: 'createAccountPassword',
  createAccountNewslettersCheckbox: 'createAccountNewslettersCheckbox',
  signUpButton: 'signUpButton',
  loginRegistrationContinueButton: 'loginRegistrationContinueButton',
  forgotPasswordEmail: 'forgotPasswordEmail',
  forgotPasswordSubmit: 'forgotPasswordSubmit',
} as const;

export const blurIds = {
  overlaySignInBtn: 'overlaySignInBtn',
  overlayAcceptTermsBtn: 'overlayAcceptTermsBtn',
  overlaySignInMsg: 'overlaySignInMsg',
  overlayAcceptTermsMsg: 'overlayAcceptTermsMsg',
  blurredListingImageCarousel: 'blurredListingImageCarousel',
  blurredListingPrice: 'blurredListingPrice',
  termsOfUseAcceptButton: 'termsOfUseAcceptButton',
} as const;

export const listingPageModalIds = {
  openAddressPage: 'listingModalOpenAddressPage',
  closeListingModal: 'closeListingModal',
  listingImageCarousel: 'listingImageCarousel',
  modalPriceInfoSection: 'listingModalPriceInfo',
  priceHistoryBtn: 'priceHistoryButton',
  virtualTourBtn: 'virtualTourBtn',
  takeTourBtn: 'takeATourButton',
  modalListingStatus: 'modalListingStatus',
  listingStatus: 'listingStatus',
  listingPriceModal: 'listingPriceModal',
  soldListingListedPrice: 'soldListingListedPrice',
  modalListingTimeStamp: 'modalListingTimeStamp',
  listingAddress: 'streetAddress',
  listingBedIcon: 'listingBedIcon',
  listingBathrooms: 'listingBathIcon',
  listingSqft: 'listingDimensionsIcon',
  listingCar: 'listingCarIcon',
  editIcon: 'EditIcon',
  closeIcon: 'CloseIcon',
  keyFacts: 'KeyFacts',
  tabLink: 'TabLink',
  keyFactsTabLink: 'KeyFactsTabLink',
  descriptionTabLink: 'DescriptionTabLink',
  roomsTabLink: 'RoomsTabLink',
  mapTabLink: 'MapTabLink',
  listingKeyFactsTab: 'listingKeyFactsTab',
  listingDescriptionTab: 'listingDescriptionTab',
  listingRoomsTab: 'listingRoomsTab',
  listingMapTab: 'listingMapTab',
  listingSchoolsTab: 'listingSchoolsTab',
  listingMortgageTab: 'listingMortgageTab',
  listingDemographicsTab: 'listingDemographicsTab',
  listingCommuteTab: 'listingCommuteTab',
  keyFactsPropertyAddress: 'keyFactsPropertyAddress',
  detailsTable: 'detailsTable',
  descriptionDetailText: 'descriptionDetailText',
  descriptionWalkScoreLabel: 'WalkScoreLabel',
  descriptionWalkScoreDescription: 'WalkScoreDescription',
  descriptionTransitScoreLabel: 'TransitScoreLabel',
  descriptionTransitScoreDescription: 'TransitScoreDescription',
  descriptionBikeScoreLabel: 'BikeScoreLabel',
  descriptionBikeScoreDescription: 'BikeScoreDescription',
  descriptionExtra: 'descriptionExtra',
  descriptionDisclaimer: 'descriptionDisclaimer',
  roomsDetailsSection: 'roomsDetailsSection',
  priceHistorySection: 'priceHistorySection',
  priceHistoryHeading: 'priceHistoryHeading',
  priceHistoryRow: 'priceHistoryRow',
  currentPriceHistoryRow: 'currentPriceHistoryRow',
  priceHistoryRowDate: 'priceHistoryRowDate',
  priceHistoryRowPrice: 'priceHistoryRowPrice',
  priceHistoryRowListPrice: 'priceHistoryRowListPrice',
  priceHistoryRowListStatus: 'priceHistoryRowListStatus',
  priceHistoryRowSoldDate: 'priceHistoryRowSoldDate',
  priceHistoryRowSoldPrice: 'priceHistoryRowSoldPrice',
  listingKeyInfo: 'listingKeyInfo',
  secondaryInfo: 'listingSecondaryInfo',
  charts: 'listingCharts',
  avgSoldPriceTabLink: 'Avg.SoldPriceTabLink',
  avgSoldPriceTab: 'avgSoldPriceTab',
  salesActivityTabLink: 'SalesActivityTabLink',
  salesActivityTab: 'salesActivityTab',
  zoopraisal: 'listingZoopraisal',
  successMessage: 'listingSuccessMessage',
  similarListingsSections: 'similarListingsSections',
  comparableProperties: 'comparableProperties',
  connectionRequestForm: 'connectionRequestForm',
  connectionRequestFormTitle: 'connectionRequestFormTitle',
  connectionRequestPopupCloseButton: 'connection-request-popupCloseButton',
  preconCard: 'preconCard',
  preconFormTitle: 'preconFormTitle',
  preconFormDescription: 'preconFormDescription',
  preconFormFieldFirstName: 'preconFormFieldFirstName',
  preconFormFieldLastName: 'preconFormFieldLastName',
  preconFormFieldEmail: 'preconFormFieldEmail',
  preconFormFieldPhone: 'preconFormFieldPhone',
  preconFormFieldCheckbox: 'preconFormFieldCheckbox',
  preconFormFieldComment: 'preconFormFieldComment',
  preconSubmitRequestButton: 'preconSubmitRequestButton',
  preconPhotoGallery: 'precon-photo-gallery',
  preconTitle: 'title',
  preconPricing: 'pricing',
  preconDeveloper: 'developer',
  preconLocation: 'location',
  preconCoverImage: 'coverImage',
  preconEmptyCoverImage: 'emptyCoverImage',
  preconOccupancyDate: 'occupancyDate',
  requestfirstname: 'requestfirstname',
  requestlastname: 'requestlastname',
  requestemail: 'requestemail',
  requestphonenumber: 'requestphonenumber',
  requestAddComment: 'requestAddComment',
  requestComments: 'requestComments',
  requestViewingCheckbox: 'requestViewingCheckbox',
  newslettersCheckbox: 'createAccountNewslettersCheckbox',
  schoolsTabLink: 'SchoolsTabLink',
  elementarySchoolsButton: 'elementarySchoolsButton',
  secondarySchoolsButton: 'secondarySchoolsButton',
  mortgageTabLink: 'MortgageTabLink',
  demographicsTabLink: 'DemographicsTabLink',
  commuteTabLink: 'CommuteTabLink',
  listingsRadioButton: 'ListingsRadioButton',
  favouritePrompt: 'FavouritePrompt',
  favouritePromptText: 'FavouritePromptText',
  favouritePromptAddButton: 'FavouritePromptAddButton',
  favouritePromptSavedListingsLink: 'FavouritePromptSavedListingsLink',
} as const;

export const listingCardIds = {
  listingGrid: 'listing-grid',
  listingCard: 'listingCard',
  listingImage: 'listingCardImage',
  listingPrice: 'listingPrice',
  listingCardPrice: 'listingCardPrice',
  listingTimeStamp: 'listingTimeStamp',
  listingAddress: 'street-address',
  listingBeds: 'listingBeds',
  listingBathrooms: 'listingBathrooms',
  listingSqft: 'listingSqft',
  listingMlsNum: 'listingMls',
  favouriteToggle: 'favouriteToggle',
  listingsSwiper: 'listingsSwiper',
  newListingRow: 'newListingRow',
  nearbyListings: 'NearbyListings',
  nearbyViewMore: 'NearbyViewMore',
} as const;

export const mapPageIds = {
  listingsSidebar: 'listingsSidebar',
  listingsCount: 'listingsCount',
  hideShowListBtn: 'hideShowListBtn',
  listingsScrollableList: 'listingsScrollableList',
  listingPinPopover: 'listingPinPopover',
  listingsCardPopover: 'listingCardSmall',
  mapPageSearchbar: 'mapPageSearchbar',
  mapPageSearchbarClear: 'mapPageSearchbarClear',
  mapListToggleButton: 'mapListToggleButton',
  sitemapProvince: 'sitemapProvince',
  listingMap: 'listingMap',
  nearbyAreas: 'NearbyAreas',
  fullScreenListings: 'fullScreenListings',
  mapPinType: 'listing-cluster',
  mapPin: 'mapPin',
} as const;

export const searchFilterIds = {

  filterModal: 'filter-modal',
  breadcrumbs: 'Breadcrumbs',
  resetFiltersButton: 'resetFiltersButton',
  moreButtonHomepage: 'moreButtonHomepage',
  searchInput: 'search-input',

  //Buy or Rent filter
  buyOrRentDropdownMap: 'buyOrRentDropdownMap',
  buyListingsOptionButton: 'BuyListingsRadioButtonOption',
  rentListingsOptionButton: 'RentListingsRadioButtonOption',
  buyRentDropdown: 'buyRentDropdown',
  homepageBuyRentDropdownOptions: 'homepageBuyRentDropdownOptions',
  RentRadioButtonOption: 'RentRadioButtonOption',

  // Listing Status Filter
  listingStatusDropdownMap: 'listingStatusDropdownMap',
  activeListingOptionButton: 'ActiveListingsRadioButtonOption',
  soldListingOptionButton: 'SoldListingsRadioButtonOption',
  leasedListingOptionButton: 'LeasedListingsRadioButtonOption',
  expiredListingOptionButton: 'ExpiredListingsRadioButtonOption',
  listingStatusOptionsHomepage: 'listingStatusOptionsHomepage',

  // Home Type Filter
  homeTypeDropdownMap: 'homeTypeDropdownMap',
  homeTypeDropdownMapLabel: 'homeTypeDropdownMapLabel',
  homeTypeDropdownSavedSearch: 'homeTypeDropdownSavedSearch',
  duplexes: 'Duplexes',
  detachedHouses: 'DetachedHouses',
  threeBedHouses: '3BedHouses',
  twoBedCondos: '2BedCondos',
  oneBedCondos: '1BedCondos',
  condosUnder500K: 'CondosUnder500K',
  housesUnder1Mil: 'HousesUnder1Mil',
  condos1000000: 'Condos1000000',
  searchBySchools: 'SearchBySchools',
  schoolPin: 'schoolPin',
  mostExpensiveHouses: 'MostExpensiveHouses',
  luxuryCondos: 'LuxuryCondos',
  cheapestCondosInToronto: 'CheapestCondosInToronto',
  cheapestHousesInToronto: 'CheapestHousesInToronto',

  // Price Filter
  priceRangeDropdownMap: 'priceRangeDropdownMap',
  priceFilterMinInput: 'priceFilterMinInput',
  priceFilterMaxInput: 'priceFilterMaxInput',
  priceRangeDropdownApply: 'priceRangeDropdownApply',
  minPriceFilterOptions: 'minPriceFilterOptions',
  maxPriceFilterOptions: 'maxPriceFilterOptions',
  priceFilterSlider: 'priceFilterSlider',
  priceRangeDropdownReset: 'priceRangeDropdownReset',

  // Beds Filter
  bedsDropdownMap: 'bedsDropdownMap',
  bedOptionsDropdownSelect: 'bedOptionsDropdownSelect',
  mapButtonAreaPage: 'listButtonMapPage',
  saveSearchButtonMapPage: 'saveSearchButtonMapPage',
  bedOptionsDropdown: 'bedOptionsDropdown',

  // Filter Modal Constants
  activeRadioButtonOption: 'ActiveRadioButtonOption',
  soldRadioButtonOption: 'SoldRadioButtonOption',
  expiredRadioButtonOption: 'ExpiredRadioButtonOption',
  bedsSavedSearchesMinus: 'bedsSavedSearchesMinus',
  bedsSavedSearchesPlus: 'bedsSavedSearchesPlus',
  bathroomsSavedSearchesPlus: 'bathroomsSavedSearchesPlus',
  applyFiltersButton: 'applyFiltersButton',
  deleteButtonModal: 'deleteSearch',
  moreButtonMapPage: 'moreButtonMapPage',
  okButtonModal: 'okButtonModal',
  saveEditSearch: 'saveEditSearch',
  parkingSavedSearchesPlus: 'parkingSavedSearchesPlus',
  saveSearchButton: 'saveSearchButton',
  saveSearchModal: 'saveSearchModal',
  savedSearchNameModal: 'savedSearchNameModal',
  savedSearchNotificationFrequency: 'savedSearchNotificationFrequency',  
  maintenanceFees: 'maintenanceFees',

  // Dropdown
  locationsDropdownSection: 'LocationsDropdownSection',
  listingsDropdownSection: 'ListingsDropdownSection',
  buildingsDropdownSection: 'BuildingsDropdownSection',
  schoolsDropdownSection: 'SchoolsDropdownSection',
  popularSearchesDropdownSection: 'PopularSearchesDropdownSection',
  listingStatusDropdownHomepage: 'listingStatusDropdownHomepage',
  homeTypeDropdownHomepage: 'homeTypeDropdownHomepage',
  priceRangeDropdownHomepage: 'priceRangeDropdownHomepage',
  bedsDropdownHomepage: 'bedsDropdownHomepage',
  sortByDropdown: 'sort-by-dropdown',

  // Check Box
  checkboxOption: 'CheckboxOption',
  houseCheckboxOption: 'HouseCheckboxOption',
  townhouseCheckboxOption: 'TownhouseCheckboxOption',
  condoCheckboxOption: 'CondoCheckboxOption',
  semiDetachedCheckboxOption: 'Semi-DetachedCheckboxOption',
  attachedRowCheckboxOption: 'Attached/RowCheckboxOption',
  detachedCheckboxOption: 'DetachedCheckboxOption',
  singleFamilyCheckbox: 'singleFamilyCheckbox',
  singleFamilyBasementCheckbox: 'singleFamilyBasementCheckbox',
  duplexCheckbox: 'duplexCheckbox',
  triplexCheckbox: 'triplexCheckbox',
  fourplexCheckbox: 'fourplexCheckbox',
  garageCheckbox: 'garageCheckbox',
  poolCheckbox: 'poolCheckbox',
  fireplaceCheckbox: 'fireplaceCheckbox',
  openHousesCheckbox: 'openHousesCheckbox',
  waterfrontCheckbox: 'waterfrontCheckbox',
  orMoreBedCheckboxOption: 'orMoreBedCheckboxOption',
  createAccountNewslettersCheckbox: 'createAccountNewslettersCheckbox',

  // Saved Searches Header + Dropdown
  viewAllSavedSearches: 'viewAllSavedSearches',
  myZoocasa: 'myZoocasa',
  savedSearchCard: 'SavedSearchCard',
  savedPriceRange: 'PriceRange',
  savedBedroom: 'Bedroom',
  savedBathroom: 'Bathroom',
  savedParking: 'Parking',
  savedSearch: 'SavedSearch',
  daysOnMarketDropdown: 'daysOnMarketDropdown',
  lockerDropdown: 'lockerDropdown',
  minPriceSavedSearch: 'minPriceSavedSearch',
  maxPriceSavedSearch: 'maxPriceSavedSearch',
  minSquareFeetSavedSearch: 'minSquareFeetSavedSearch',
  maxSquareFeetSavedSearch: 'maxSquareFeetSavedSearch',
  dropdownOption: 'DropdownOption',
  bedsSavedSearches: 'bedsSavedSearches',
  bedsCheckboxSavedSearches: 'bedsCheckboxSavedSearches',
  bedsCheckboxSavedSearchesInput: 'bedsCheckboxSavedSearchesInput',
  bathroomsSavedSearches: 'bathroomsSavedSearches',
  bathroomsCheckboxSavedSearches: 'bathroomsCheckboxSavedSearches',
  parkingSavedSearches: 'parkingSavedSearches',
  parkingCheckboxSavedSearches: 'parkingCheckboxSavedSearches',
  providerIdInput: 'providerIdInput',

  // search bar
  searchbar: 'searchbar',
  searchbarButton: 'searchbarButton',
  searchbarFilters: 'searchbarFilters',
  recentSearchesDropdownSection: 'RecentSearchesDropdownSection',
  searchbarFilterButton: 'searchbarFilterButton',

  // map
  searchMap: 'searchMap',
  mapZoomIn: 'mapZoomIn',

  // School
  schoolPinPopover: 'schoolPinPopover',
  showSchoolPins: 'showSchoolPins',
  showSchoolsToggle: 'showSchoolsToggle',
  applySchoolFilters: 'applySchoolFilters',
} as const;

export const headerIds = {
  header: 'header',
  zoocasaHeaderLogo: 'zoocasaHeaderLogo',
  headerSearchIcon: 'HeaderSearchIcon',
  soldPricesHeader: 'soldPricesHeader',
  newHomesHeader: 'newHomesHeader',
  sellWithUsHeader: 'sellWithUsHeader',
  mapHeader: 'mapHeader',
  marketInsightsHeader: 'marketInsightsHeader',
  mySearchesHeader: 'mySearchesHeader',
  loggedInUser: 'loggedInUser',
  loginHeader: 'loginHeader',
  loginMobile: 'loginMobile',
  mobileMenu: 'mobileMenu',
  mobilePanel: 'mobilePanel', 
  pageHeading: 'pageHeading',
} as const;

export const smartChat = {
  assistantAvatar: 'assistantAvatar',
  assistantChatMessage: 'assistantChatMessage',
  userChatMessage: 'userChatMessage',
  closePopup: 'closePopup',
  chatIcon: 'chatIcon',
  popupMessage: 'popupMessage',
  closeChatHeader: 'closeChatHeader',
  closeChatIcon: 'closeChatIcon',
  sendBtn: 'sendBtn',
  connectWithAgentBtn: 'connectWithAgentBtn',
  bookViewingBtn: 'bookViewingBtn',
  haveQuestionsBtn: 'haveQuestionsBtn',
  textBar: 'textBar',
  smartChatHeader: 'smartChatHeader',
  infoOutlinedIcon: 'InfoOutlinedIcon',
  closeIcon: 'closeIcon',
} as const;

export const cookieBanner = {
  consentBlackbar: 'consent_blackbar',
  trusteConsentTrack: 'truste-consent-track',
  trusteConsentContent: 'truste-consent-content',
  trusteCookieImage: 'truste-cookie-image',
  trusteConsentText: 'truste-consent-text',
  trusteConsentButtons: 'truste-consent-buttons',
  trusteRepopMsg: 'truste-repop-msg',
  trusteConsentAcceptAllButton: 'truste-consent-button',
  trusteConsentRejectAllButton: 'truste-consent-required',
  trusteConsentCustomizeButton: 'truste-show-consent',
} as const;

export const buyWithZoocasa = {
  buyFirstHomeSection: 'buy-first-home',
  connectWithAgentBtn: 'connect-with-agent',
  callUsNowBtn: 'call-us',
  createAccountSection: 'create-account-section',
  createAccountBtn: 'create-account',
  buyersGuideSection: 'buyers-guide-section',
  buyersGuideBtn: 'buyers-guide',
  viewMoreAvailableBtn: 'view-more-available',
  viewMoreSoldBtn: 'view-more-sold',
} as const;

export const agentSearchIds = {
  agentSearchHeader: 'agentSearchHeader',
  countryDropdown: 'countryDropdown',
  locationInput: 'locationInput',
  agentNameInput: 'agentNameInput',
  agentsResultCount: 'agentsResultCount',
  agentCard: 'agentCard',
  agentNoResultsMessage: 'agentNoResultsMessage',
  agentProfileFirstName: 'agentProfileFirstName',
  agentProfileLastName: 'agentProfileLastName',
  agentProfileContactInfo: 'agentProfileContactInfo',
  agentProfileAbout: 'agentProfileAbout',
  agentCardName: 'agentCardName',
  agentCardLocation: 'agentCardLocation',
  agentCardBio: 'agentCardBio',
  countryDropdownOption: 'countryDropdownOption',
  agentSearchIcon: 'agentSearchIcon',
  agentSearchDropdownOption: 'agentSearchDropdownOption',
} as const;