import Cookies from 'js-cookie';
import defaultListingParams from 'contexts/preferences/listing-params/defaults';
import { getJSON, getObjectFromRequestCookie } from 'utils/cookies';
import { LISTING_PARAMS_COOKIE_NAME } from 'constants/cookies';

import type { IncomingMessage } from 'http';
import type { ListingParams } from 'contexts/preferences/listing-params/types';

export const storageKey = LISTING_PARAMS_COOKIE_NAME;

export function extractListingParamsData(req?: IncomingMessage): ListingParams {
  return getObjectFromRequestCookie<ListingParams>(storageKey, req) || getJSON(Cookies.get(storageKey)) || defaultListingParams;
}
