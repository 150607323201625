import defaultListingParams from 'contexts/preferences/listing-params/defaults';
import { getJSON } from 'utils/cookies';
import { storageKey } from 'contexts/preferences/listing-params/utils';
import { DEFAULT_EXPIRE_DAYS } from 'constants/cookies';
import Cookies from 'js-cookie';
import { cloneDeep } from 'lodash';
import deepmerge from 'deepmerge';

import type { ListingParams as TListingParams } from 'contexts/preferences/listing-params/types';

export default class ListingParams {
  sort: 'price' | '-price' | '-date' | 'date' | '-bedrooms' | 'bedrooms' | '-bathrooms' | 'bathrooms';
  filter: {
    rental: boolean;
    status: 'available' | 'not-available' | 'not-available-sold' | 'not-available-other';
    slug: string;
    latitude: number;
    longitude: number;
    zoom: number;
    homeType: {
      houseDetached: boolean;
      houseSemidetached: boolean;
      houseAttached: boolean;
      townhouse: boolean;
      condo: boolean;
    };
    priceMin: number | null;
    priceMax: number | null;
    listedSince: string | null;
    listedTo: string | null;
    bedrooms: string;
    sqftMin: number | null;
    sqftMax: number | null;
    bathrooms: string;
    parkingSpaces: string;
    openHouse: boolean;
    garage: boolean;
    pool: boolean;
    fireplace: boolean;
    waterfront: boolean;
    additional: {
      house: {
        singleFamily: boolean;
        basementApartment: boolean;
        duplex: boolean;
        triplex: boolean;
        'fourplex+': boolean;
      };
      condoOrTownhouse: {
        locker: 'any' | 'yes' | 'no';
        maintenanceFee: number | null;
      };
    };
    areaName: string;
    boundary: string | null;
    providerId: string | null;
  };

  constructor(listingParams: Record<string, unknown>) {
    Object.assign(this, listingParams);
  }

  setSort = (sort: TListingParams['sort']) => {
    this.sort = sort;
    updateListingParamsCookie(this);
  };
  setFilter = (filter: TListingParams['filter']) => {
    this.filter = cloneDeep(deepmerge(defaultListingParams.filter, filter));
    updateListingParamsCookie(this);
  };
  setSlug = (slug: TListingParams['filter']['slug']) => {
    this.filter.slug = slug;
    updateListingParamsCookie(this);
  };
  setLongitude = (longitude: TListingParams['filter']['longitude']) => {
    this.filter.longitude = longitude;
    updateListingParamsCookie(this);
  };
  setLatitude = (latitude: TListingParams['filter']['latitude']) => {
    this.filter.latitude = latitude;
    updateListingParamsCookie(this);
  };
  setZoom = (zoom: TListingParams['filter']['zoom']) => {
    if (zoom > 1 && zoom < 23) {
      this.filter.zoom = Math.round(zoom);
    }
    updateListingParamsCookie(this);
  };
  setStatus = (status: TListingParams['filter']['status']) => {
    this.filter.status = status;
    updateListingParamsCookie(this);
  };
  setRental = (rental: TListingParams['filter']['rental']) => {
    this.filter.rental = rental;
    this.filter.priceMin = 0;
    this.filter.priceMax = null;
  };
  setBedrooms = (bedrooms: TListingParams['filter']['bedrooms']) => {
    this.filter.bedrooms = bedrooms;
    updateListingParamsCookie(this);
  };
  setBathrooms = (bathrooms: TListingParams['filter']['bathrooms']) => {
    this.filter.bathrooms = bathrooms;
    updateListingParamsCookie(this);
  };
  setSqftMin = (sqftMin: TListingParams['filter']['sqftMin']) => {
    this.filter.sqftMin = sqftMin;
    updateListingParamsCookie(this);
  };
  setSqftMax = (sqftMax: TListingParams['filter']['sqftMax']) => {
    this.filter.sqftMax = sqftMax;
    updateListingParamsCookie(this);
  };
  setParkingSpaces = (parkingSpaces: TListingParams['filter']['parkingSpaces']) => {
    this.filter.parkingSpaces = parkingSpaces;
    updateListingParamsCookie(this);
  };
  setListedSince = (listedSince: TListingParams['filter']['listedSince']) => {
    this.filter.listedSince = listedSince;
    updateListingParamsCookie(this);
  };
  setListedTo = (listedTo: TListingParams['filter']['listedTo']) => {
    this.filter.listedTo = listedTo;
    updateListingParamsCookie(this);
  };
  setLocker = (locker: TListingParams['filter']['additional']['condoOrTownhouse']['locker'] ) => {
    this.filter.additional.condoOrTownhouse.locker = locker;
    updateListingParamsCookie(this);
  };
  setMaintainenceFee = (maintenanceFee: TListingParams['filter']['additional']['condoOrTownhouse']['maintenanceFee']) => {
    this.filter.additional.condoOrTownhouse.maintenanceFee = maintenanceFee;
    updateListingParamsCookie(this);
  };
  setPriceMin = (priceMin: TListingParams['filter']['priceMin']) => {
    this.filter.priceMin = priceMin;
    updateListingParamsCookie(this);
  };
  setPriceMax = (priceMax: TListingParams['filter']['priceMax']) => {
    this.filter.priceMax = priceMax;
    updateListingParamsCookie(this);
  };
  setPrice(priceMin: TListingParams['filter']['priceMin'], priceMax: TListingParams['filter']['priceMax']) {
    this.filter.priceMin = priceMin;
    this.filter.priceMax = priceMax;
    updateListingParamsCookie(this);
  }
  setHomeType = (homeType: TListingParams['filter']['homeType']) => {
    this.filter.homeType = { ...homeType };
    updateListingParamsCookie(this);
  };
  setBoundary = (boundary: TListingParams['filter']['boundary']) => {
    this.filter.boundary = boundary;
    updateListingParamsCookie(this);
  };
  setAreaName = (areaName: TListingParams['filter']['areaName']) => {
    this.filter.areaName = areaName;
    updateListingParamsCookie(this);
  };
}

export function createListingParams(params?: TListingParams) {
  if (!params) {
    params = getJSON(Cookies.get(storageKey));
  }
  const properties = { ...defaultListingParams, ...params };
  const listingParams = new ListingParams(properties);
  updateListingParamsCookie(listingParams);
  return listingParams;
}

function updateListingParamsCookie(params: ListingParams) {
  Cookies.set(storageKey, JSON.stringify(params), { expires: DEFAULT_EXPIRE_DAYS });
}

export * from './types';