/**
 * @see https://developers.google.com/maps/documentation/geocoding/overview#Types
 */
export enum AutocompletePredictionTypes {
  ROUTE = 'route',
  GEOCODE = 'geocode',
  LOCALITY = 'locality',
  SUBLOCALITY = 'sublocality',
  NEIGHBORHOOD = 'neighborhood',
  POSTAL_CODE = 'postal_code',
  COUNTRY = 'country',
  SUBLOCALITY_LEVEL_1 = 'sublocality_level_1',
  ADMINISTRATIVE_AREA_LEVEL_1 = 'administrative_area_level_1',
  ADMINISTRATIVE_AREA_LEVEL_2 = 'administrative_area_level_2',
  ADMINISTRATIVE_AREA_LEVEL_3 = 'administrative_area_level_3',
}

/**
 * @see https://developers.google.com/maps/documentation/places/web-service/supported_types#table3
 */
export enum AutocompletePredictionRestrictionTypes {
  CITIES = '(cities)',
  REGIONS = '(regions)',
  GEOCODE = 'geocode',
  ADDRESS = 'address',
}

export type QueryResultType = { 
  city: google.maps.places.AutocompletePrediction[];
  neighborhood: google.maps.places.AutocompletePrediction[];
  street: google.maps.places.AutocompletePrediction[];
};

export type GoogleAutocompleteProviderType = (
  input: string
) => Promise<QueryResultType>;

export type QuerySettingsLimitType = { 
    city: number; 
    neighborhood: number; 
    street: number;
};

export type QuerySettingsType = { 
    limits: QuerySettingsLimitType; 
    autocompleteProvider?: GoogleAutocompleteProviderType;
 };

export const SEARCH_PREDICTION_SOURCE_GOOGLE = 'google';