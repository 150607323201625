import type { ListingParams } from 'contexts/preferences/listing-params/types';

export const torontoLocation = {
  latitude: 43.653226,
  longitude: -79.3831843,
};

const DEFAULT_LISTING_PARAMS = Object.freeze<ListingParams>({
  sort: '-date',
  filter: {
    rental: false,
    status: 'available',
    slug: 'toronto-on',
    ...torontoLocation,
    zoom: 14,
    homeType: {
      houseDetached: true,
      houseSemidetached: true,
      houseAttached: true,
      townhouse: true,
      condo: true,
    },
    priceMin: null,
    priceMax: null,
    listedSince: null,
    listedTo: null,
    bedrooms: '0+',
    sqftMin: null,
    sqftMax: null,
    bathrooms: '1+',
    parkingSpaces: '0+',
    openHouse: false,
    garage: false,
    pool: false,
    fireplace: false,
    waterfront: false,
    additional: {
      house: {
        singleFamily: false,
        basementApartment: false,
        duplex: false,
        triplex: false,
        'fourplex+': false,
      },
      condoOrTownhouse: {
        locker: 'any',
        maintenanceFee: null,
      },
    },
    areaName: 'Toronto, ON',
    boundary: null,
    providerId: null,
  },
});

export default DEFAULT_LISTING_PARAMS;
