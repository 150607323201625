import { useEffect, useContext, useState } from 'react';
import { PreferencesContext, IPreferences } from 'contexts/preferences';
import Cookies from 'js-cookie';
import { DEFAULT_EXPIRE_DAYS } from 'constants/cookies';

export default function useWindowWidth() {
  const { screen } = useContext(PreferencesContext) as IPreferences;
  const [windowWidth, setWindowWidth] = useState(screen?.width || 0);

  useEffect(() => {
    const handler = () => {
      setWindowWidth(window.innerWidth);
      Cookies.set('screen', JSON.stringify({ width: window.innerWidth }), { expires: DEFAULT_EXPIRE_DAYS });
    };
    handler();
    window.addEventListener('resize', handler, { passive: true });
    return () => window.removeEventListener('resize', handler);
  }, []);

  return windowWidth;
}
