import { cloneDeep } from 'lodash';
import { updatedDiff } from 'deep-object-diff';
import DEFAULT_LISTING_PARAMS from 'contexts/preferences/listing-params/defaults';
import { getHomeTypeSelection } from 'utils/listing-query-helper';
import { generateRouteMatchObjectFromPath, RouteMatchObject } from '../route-matchers';
import { dasherize } from '@zoocasa/node-kit/strings/dasherize';

import type { Filter, FlattenFilter, Sort } from 'contexts/preferences/listing-params/types';
import type { FiltersType } from './area_listings_page_view_model';
import type { PartialDeep } from 'type-fest';

type TargetedUrlHelper = {
  url: string;
  filters: Filter;
  sort: Sort;
  pageNumber: number;
  fallbackSlug?: string;
  filtersChanged?: boolean;
};

export default function getTargetedUrl({ url, filters, sort, pageNumber, fallbackSlug = '', filtersChanged = true }: TargetedUrlHelper) {
  const location = new URL(url);
  const defaultFilters: Partial<FiltersType> = cloneDeep(DEFAULT_LISTING_PARAMS.filter);
  const userFilters: PartialDeep<FiltersType> = cloneDeep(filters);

  // Reset page if filters have changed
  if (filtersChanged) {
    pageNumber = 1;
  }
  
  // Remove slug and areaName as we don't need to pass it in the filter query params
  delete defaultFilters.slug;
  delete userFilters.slug;
  delete defaultFilters.areaName;
  delete userFilters.areaName;
  delete defaultFilters.latitude;
  delete userFilters.latitude;
  delete defaultFilters.longitude;
  delete userFilters.longitude;
  delete defaultFilters.zoom;
  delete userFilters.zoom;
  delete defaultFilters.boundary;
  delete userFilters.boundary;

  const flattenDefaultFilters = getFlattenFilters(defaultFilters);
  const flattenUserFilters = getFlattenFilters(userFilters);
  
  const updatedFiltersDiff: Partial<FlattenFilter> = updatedDiff(flattenDefaultFilters, flattenUserFilters);
  const extendedFiltersSearchParams = filterToURLSearchParams(updatedFiltersDiff);
  const routeMatchObject = generateRouteMatchObjectFromPath(location.pathname);

  const isHomeDetachedTheSame = !Object.hasOwn(updatedFiltersDiff, 'houseDetached');
  const isHomeSemidetachedTheSame = !Object.hasOwn(updatedFiltersDiff, 'houseSemidetached');
  const isHomeAttachedTheSame = !Object.hasOwn(updatedFiltersDiff, 'houseAttached');
  const isTownhouseTheSame = !Object.hasOwn(updatedFiltersDiff, 'townhouse');
  const isCondoTheSame = !Object.hasOwn(updatedFiltersDiff, 'condo');
  const isBedroomTheSame = !Object.hasOwn(updatedFiltersDiff, 'bedrooms');
  const isBathroomTheSame = !Object.hasOwn(updatedFiltersDiff, 'bathrooms');
  const isWaterfrontTheSame = !Object.hasOwn(updatedFiltersDiff, 'waterfront');
  const isGarageTheSame = !Object.hasOwn(updatedFiltersDiff, 'garage');
  const isPoolTheSame = !Object.hasOwn(updatedFiltersDiff, 'pool');
  const isOpenHouseTheSame = !Object.hasOwn(updatedFiltersDiff, 'openHouse');
  const isListingStatusTheSame = !Object.hasOwn(updatedFiltersDiff, 'status');
  const isRentalTheSame = !Object.hasOwn(updatedFiltersDiff, 'rental');

  // ----- Get location prefix
  const { city, provinceCode, province, country, neighbourhood, street } = routeMatchObject;
  const isNeighbourhoodOrStreet = neighbourhood || street;
  const locationPrefix = city ? `${city}-${provinceCode}-` : province ? `${province}-` : country ? `${country}-` : '';
  // ----- Check which home type is different - if not check if open house is true
  const activeHomeType = '/' + getHomeTypeSelection(filters.homeType).toLowerCase();
  
  if (pageNumber > 1) {
    location.searchParams.set('page', `${pageNumber}`);
  }
  
  if (sort != DEFAULT_LISTING_PARAMS.sort) {
    location.searchParams.set('sort', sort);
  }
  
  if (pageNumber > 1 && fallbackSlug?.length > 0) {
    location.searchParams.set('slug-fallback', `${fallbackSlug}`);
  }
  const extendedFilteredUrl = getExtendedFilteredUrl(`${location.origin}/${grabPathName(location.pathname, routeMatchObject)}`, extendedFiltersSearchParams, location.searchParams);

  // Do not generate SEO-friendly variants for neighbourhood area pages
  if (isNeighbourhoodOrStreet) {
    return extendedFilteredUrl;
  }

  const isLeasedStatus = !isRentalTheSame && filters.status === 'not-available-sold';

  // If more than 1 home-type is different -> /filter?
  const inactiveHouseFiltersCount = [isHomeDetachedTheSame, isHomeSemidetachedTheSame, isHomeAttachedTheSame].filter(value => !value).length;
  const isHouseActive = inactiveHouseFiltersCount !== 3;
  const homeTypeComparison = [isHouseActive, isTownhouseTheSame, isCondoTheSame].filter(value => !value).length;
  if (homeTypeComparison === 1) {
    return extendedFilteredUrl;
  }

  const s = [isHomeDetachedTheSame, isHomeSemidetachedTheSame, isHomeAttachedTheSame].filter(value => value).length;
  if (s < 3 && s > 0) {
    return extendedFilteredUrl;
  }

  const isSingleHomeType = [isHouseActive, isTownhouseTheSame, isCondoTheSame].filter(value => value).length === 1;

  if (!isBedroomTheSame || !isBathroomTheSame) {
    return extendedFilteredUrl;
  }

  const filtersToCheck = [!isSingleHomeType, isWaterfrontTheSame, isGarageTheSame, isPoolTheSame, isOpenHouseTheSame, isRentalTheSame, isListingStatusTheSame];
  const filtersComparison = filtersToCheck.filter(value => !value).length;
  if (filtersComparison > 1 && !isLeasedStatus) {
    return extendedFilteredUrl;
  }

  // Special case for leased listings, since these have both the rental and listing status different from the default parameters
  const filtersToCheckWithLeased = [!isSingleHomeType, isWaterfrontTheSame, isGarageTheSame, isPoolTheSame, isOpenHouseTheSame, !isLeasedStatus];
  const filtersToCheckWithLeasedComparison = filtersToCheckWithLeased.filter(value => !value).length;
  if (filtersToCheckWithLeasedComparison > 1) {
    return extendedFilteredUrl;
  }

  // If not then check if anything else is different
  const keysToIgnore = ['rental', 'bedrooms', 'bathrooms', 'waterfront', 'garage', 'pool', 'openHouse', 'areaName', 'slug', 'status', 'houseDetached', 'houseSemidetached', 'houseAttached', 'townhouse', 'condo'];
  
  if (activeHomeType !== 'homes') {
    keysToIgnore.push(...['houseDetached', 'houseSemidetached', 'houseAttached', 'townhouse', 'condo']);
  }
  
  keysToIgnore.sort();
  
  const updatedFiltersDiffKeys = Object.keys(updatedFiltersDiff).sort();
  const difference = updatedFiltersDiffKeys.filter(x => !keysToIgnore.includes(x));

  if (difference.length > 0) {
    return extendedFilteredUrl;
  }

  let seoFriendlyKeywords = '';
  if (isSingleHomeType) {
    seoFriendlyKeywords += `${activeHomeType}`;
  } else if (isLeasedStatus) {
    seoFriendlyKeywords += '/leased';
  } else if (!isRentalTheSame) {
    seoFriendlyKeywords += '/for-rent';
  } else if (!isListingStatusTheSame) {
    if (filters.status === 'not-available-sold') {
      seoFriendlyKeywords += '/sold';
    }
    else {
      seoFriendlyKeywords += '/past-listings';
    }
  } else if (!isOpenHouseTheSame) {
    seoFriendlyKeywords += '/open-houses';
  } else if (!isWaterfrontTheSame) {
    seoFriendlyKeywords += '/on-waterfront';
  } else if (!isGarageTheSame) {
    seoFriendlyKeywords += '/with-garage';
  } else if (!isPoolTheSame) {
    seoFriendlyKeywords += '/with-swimming-pool';
  }

  location.pathname = `/${locationPrefix}real-estate${seoFriendlyKeywords}`;
  return location;
}

function grabPathName(windowPathName: string, routeMatchObject: RouteMatchObject) {
  const { neighbourhood, street } = routeMatchObject;
  const pathSplit = windowPathName.split('/');
  let pathName = pathSplit[1];
  if (street && neighbourhood) {
    pathName = `${pathSplit[1]}/${pathSplit[2]}/${pathSplit[3]}`;
  } else if (street) {
    pathName = `${pathSplit[1]}/${pathSplit[2]}`;
  } else if (neighbourhood) {
    pathName = `${pathSplit[1]}/${pathSplit[2]}`;
  }
  return pathName;
}

export function getExtendedFilteredUrl(url: string, extendedFiltersSearchParams: URLSearchParams, extrasSearchParams?: URLSearchParams) {
  const extendedUrl = new URL(url);
  const filterSize = [...extendedFiltersSearchParams].length;
  const extrasSize = extrasSearchParams ? [...extrasSearchParams].length : 0;
  if (filterSize > 0) {
    if (!extendedUrl.pathname.endsWith('/filter'))
      extendedUrl.pathname = `${extendedUrl.pathname}/filter`;

    for (const entry of extendedFiltersSearchParams.entries()) {
      extendedUrl.searchParams.set(entry[0], entry[1]);
    }
  }
  if (extrasSize > 0 && extrasSearchParams) {
    for (const entry of extrasSearchParams.entries()) {
      extendedUrl.searchParams.set(entry[0], entry[1]);
    }
  }

  return extendedUrl;
}

export function getFlattenFilters(filter: PartialDeep<Filter>): Partial<FlattenFilter> {
  const { additional, homeType, ...rest } = filter;
  const flattenedFilter: Partial<FlattenFilter> = { ...rest, ...homeType, ...additional?.house, ...additional?.condoOrTownhouse };
  return flattenedFilter;
}

export function filterToURLSearchParams(flattenedFilter: Partial<FlattenFilter>) {
  const urlSearchParams = new URLSearchParams();
  for (const key in flattenedFilter) {
    const originalKey = key as keyof FlattenFilter;
    let formattedKey;
    if (originalKey === 'houseAttached' || originalKey === 'houseDetached' || originalKey === 'houseSemidetached') {
      // In the past we made these shortened form in our url (i.e. houseAttached = attached), at this
      // point we need to maintain that mapping or we'll break bookmarks for our users :/
      formattedKey = key.replace('house', '').toLowerCase();
    } else {
      formattedKey = dasherize(key).replace('+', '').toLowerCase();
    }

    urlSearchParams.append(formattedKey, flattenedFilter[originalKey ] as string);
  }
 
  return urlSearchParams;
}