export enum ThemeNames {
  ZOOCASA = 'zoocasa',
  EXP_REALTY_CA = 'exprealtyCA',
  EXP_REALTY_US = 'exprealtyUS',
}

export type Themes =
  ThemeNames.ZOOCASA | 
  ThemeNames.EXP_REALTY_CA |
  ThemeNames.EXP_REALTY_US;
