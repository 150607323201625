// ------- Click events --------

// Homepage Search Options:
export const GTM_CLICK_HOMEPAGE_SEARCH_OPTION_BUY = 'UiHomepHeadButBuyClk';
export const GTM_CLICK_HOMEPAGE_SEARCH_OPTION_RENT = 'UiHomepHeadButRentClk';
export const GTM_CLICK_HOMEPAGE_SEARCH_OPTION_SOLD = 'UiHomepHeadButSoldClk';
export const GTM_CLICK_HOMEPAGE_SEARCH_OPTION_AGENTS = 'UiHomepHeadButAgentClk';

// Homepage Card Corner Buttons:
export const GTM_CLICK_HOME_PAGE_FOR_SALE_VIEW_MAP_BUTTON = 'UiHomepButMapClk';
export const GTM_CLICK_HOME_PAGE_LINKED_TEXT_BUTTON = 'UiHomepNavHomesForSaleClk';
export const GTM_CLICK_HOMEPAGE_SOLD_BUTTON = 'UiHomepButViewSoldClk';
export const GTM_CLICK_HOMEPAGE_MARKET_INSIGHTS_BUTTON = 'UiHomepButMarketInsightsClk';
export const GTM_CLICK_HOMEPAGE_FEATURED_BUTTON = 'UiHomepButFeaturedClk';

// Homepage Agent Search Card
export const GTM_CLICK_HOMEPAGE_AGENT_BUTTON = 'UiHomepButAgentSearchClk';

//Agent Profile page
export const GTM_AGENT_PROFILE_VIEW = 'UiAProfView';
export const GTM_AGENT_PROFILE_RETURN_TEXT_CLICK = 'UiAProfTxtReturnClk';
export const GTM_AGENT_PROFILE_SERVICE_BUTTON_CLICK ='UiAProfButServicesClk';
export const GTM_AGENT_JOIN_EXP_BUTTON_CLICK ='UiAProfButJoinClk';

// Homepage Card Swipe Buttons:
export const GTM_CLICK_HOMEPAGE_SWIPER_SALE = 'UiHomepIconNextSaleClk';
export const GTM_CLICK_HOMEPAGE_SWIPER_SOLD = 'UiHomepIconViewSoldClk';
export const GTM_CLICK_HOMEPAGE_SWIPER_FEATURED = 'UiHomepIconNextFeaturedClk';

// Homepage Content Blocks:
export const GTM_CLICK_HOMEPAGE_BUYERS_CARD_CLICK = 'UiHomepCardBuyersGuideClk';
export const GTM_CLICK_HOMEPAGE_BUYERS_BUTTON_CLICK = 'UiHomepButBuyersGuideClk';
export const GTM_CLICK_HOMEPAGE_SELLERS_CARD_CLICK = 'UiHomepCardSellersGuideClk';
export const GTM_CLICK_HOMEPAGE_SELLERS_BUTTON_CLICK = 'UiHomepButSellersGuideClk';

// Listing Cards:
export const GTM_CLICK_HOMEPAGE_SALE = 'UiHomepCardSaleClk';
export const GTM_CLICK_HOMEPAGE_SOLD = 'UiHomepCardSoldClk';
export const GTM_CLICK_HOMEPAGE_SOLD_SIGN_IN = 'UiHomepButSigninSoldClk';
export const GTM_CLICK_HOMEPAGE_FEATURED = 'UiHomepCardFeaturedCardClk';
export const GTM_CLICK_HOMEPAGE_MARKET_INSIGHTS = 'UiHomepCardMarketInsightsClk';
export const GTM_CLICK_LISTING_CARD = 'UiAddressCardListingClk';
export const GTM_CLICK_MAP_PIN_LISTING = 'UiSrcMapModListingClk';
export const GTM_CLICK_LISTING_SIMILAR = 'UiAddressCardSimilarClk';
export const GTM_CLICK_LISTING_MODAL_ADDRESS_TEXT = 'UiListingmTxtAddressClk';

// Buttons:
export const GTM_CLICK_AREA_PAGE_SAVE_SEARCH_BUTTON = 'UiAreaButAlertClk';
export const GTM_CLICK_AREA_CARD_SAVE_SEARCH_BUTTON = 'UiAreaCardSaveClk';
export const GTM_CLICK_AREA_CARD_EDIT_SEARCH_BUTTON = 'UiAreaButEditsaveClk';

// Search bar/button
export const GTM_CLICK_HOME_PAGE_SEARCH_BAR = 'UiHomepNavSearchBarClk';
export const GTM_CLICK_HOME_PAGE_SEARCH_BUTTON = 'UiHomepButSearchClk';

// Add to Favourite:
export const GTM_CLICK_FAV_ON_MAP = 'UiSrcCardFavAdd';
export const GTM_CLICK_FAV_ON_AREA_PAGE = 'UiAreaCardFavAdd';
export const GTM_CLICK_FAV_ON_MODAL = 'UiListingmIconFavAdd';
export const GTM_CLICK_FAV_ON_LISTING_MODAL = 'UiListingmButFavAlertClk';
export const GTM_CLICK_FAV_ON_ADDRESS_PAGE = 'UiAddressButFavAlertClk';
export const GTM_CLICK_VIEW_ON_LISTING_MODAL = 'UiListingmButFavViewClk';
export const GTM_CLICK_VIEW_ON_ADDRESS_PAGE = 'UiAddressButFavViewClk';

// User Profile Page
export const GTM_VISIT_PAGE_USER_PROFILE = 'UiProfView';
export const GTM_CLICK_PROFILE_EDIT_NAME = 'UiProflButEditNameClk';
export const GTM_CLICK_PROFILE_EDIT_EMAIL = 'UiProfButChangeEmailClk';
export const GTM_CLICK_PROFILE_CHANGE_PASSWORD = 'UiProfButChangePWClk';
export const GTM_CLICK_PROFILE_CREATE_PASSWORD = 'UiProfButCreatePWClk';
export const GTM_CLICK_PROFILE_EDIT_PHONE_NUMBER = 'UiProfButEditPhoneClk';


// EXP Theme Nagivation buttons
export const GTM_CLICK_NAV_PAGE_CHANGE = 'UiASrcNavChgPgClk';

// Agent Search Page
export const GTM_CLICK_AGENT_PAGE_LOCATION_SEARCH_BAR = 'UiASrcNavLocBarClk';
export const GTM_CLICK_AGENT_PAGE_NAME_SEARCH_BAR = 'UiASrcNavNameBarClk';
export const GTM_CLICK_AGENT_PAGE_SUGGESTED_LOCATIONS = 'UiASrcNavSgLocClk';
export const GTM_CLICK_AGENT_PAGE_SUGGESTED_NAMES = 'UiASrcNavSgNameClk';
export const GTM_CLICK_AGENT_PAGE_EDIT_COUNTRY = 'UiASrcNavCtryClk';
export const GTM_CLICK_FEATURED_AGENT_CARD = 'UiASrcCardFeatAgentClk';
export const GTM_CLICK_REGULAR_AGENT_CARD = 'UiASrcCardAgentClk';
export const GTM_VISIT_AGENT_SEARCH_PAGE = 'UiASrcView';

// Agent Profile
export const GTM_CLICK_AGENT_PROFILE_COPY_EMAIL = 'UiAProfIconCopyEmailClk';
export const GTM_CLICK_AGENT_PROFILE_COPY_PHONE = 'UiAProfIconCopyPhoneClk';
export const GTM_CLICK_AGENT_PROFILE_FACEBOOK = 'UiAProfButFacebookClk';
export const GTM_CLICK_AGENT_PROFILE_WEBSITE = 'UiAProfButWebsiteClk';

// My Zoocasa:
export const GTM_CLICK_NAV_VIEW_ALL_SAVED_SEARCHES = 'UiAllNavSavedSrchClk';
export const GTM_CLICK_NAV_MY_ZOOCASA = 'UiAllNavMyZooClk';
export const GTM_CLICK_MOBILE_BOT_NAV_ON_MAP_MY_FAV = 'UiAllToolbarFav';
// -----------------------------

// Address Page:
export const GTM_CLICK_ADDRESS_PAGE_IMAGE = 'UiAddressImgClk';
export const GTM_CLICK_ADDRESS_PAGE_IMAGE_RIGHT_ARROW = 'UiAddressButRarrowClk';
export const GTM_CLICK_ADDRESS_PAGE_PRICE_HISTORY_BUTTON = 'UiAddressButPriceHisClk';
export const GTM_CLICK_ADDRESS_PAGE_VIRTUAL_TOUR_BUTTON = 'UiAddressButVTourClk';
export const GTM_CLICK_ADDRESS_PAGE_DESCRIPTION_TAB = 'UiAddressTabDescripClk';
export const GTM_CLICK_ADDRESS_PAGE_ROOMS_TAB = 'UiAddressTabRoomsClk';
export const GTM_CLICK_ADDRESS_PAGE_COMPARABLE_PROPERTY_RIGHT = 'UiAddressRCCardSoldClk';
export const GTM_CLICK_ADDRESS_PAGE_PRICE_HISTORY_LISTING = 'UiAddressTabPriceListClk';
export const GTM_CLICK_ADDRESS_PAGE_SALES_ACTIVITY_TAB = 'UiAddressTabSalesClk';
export const GTM_CLICK_ADDRESS_PAGE_SCHOOL_NAME = 'UiAddressTabSchoolClk';
export const GTM_CLICK_ADDRESS_PAGE_MORTGAGE_TAB = 'UiAddressTabMortClk';
export const GTM_CLICK_ADDRESS_PAGE_DEMOGRAPHICS_TAB = 'UiAddressTabDemosClk';
export const GTM_CLICK_ADDRESS_PAGE_COMMUTE_TAB = 'UiAddressTabCommuteClk';
export const GTM_CLICK_ADDRESS_PAGE_COMPARABLE_LISTING_BOTTOM = 'UiAddressCardSoldClk';
export const GTM_CLICK_ADDRESS_PAGE_MAP_TAB = 'UiAddressTabMapClk';
export const GTM_CLICK_ADDRESS_PAGE_MAP_SATELLITE_VIEW = 'UiAddressButSatelliteClk';
export const GTM_CLICK_ADDRESS_PAGE_MAP_STREET_VIEW = 'UiAddressButStreetClk';
export const GTM_CLICK_ADDRESS_PAGE_SHARE_ICON = 'UiAddressIconShareClk';
export const GTM_CLICK_ADDRESS_PAGE_FAVOURITE_ICON = 'UiAddressIconFavAdd';
export const GTM_CLICK_ADDRESS_PAGE_MAIN_PVR_CTA = 'UiAddressButContactClk';
export const GTM_CLICK_ADDRESS_PAGE_PHOTO_GALLERY_PVR_CTA = 'UiAddressGalButContactClk';

// Listing Modal
export const GTM_CLICK_ADDRESS_MODAL_MAP_SATELLITE_VIEW = 'UiAddressMdlButSatelliteClk';
export const GTM_CLICK_ADDRESS_MODAL_MAP_STREET_VIEW = 'UiAddressMdlButStreetClk';

// Search Page
export const GTM_CLICK_SEARCH_PAGE_SATELLITE_VIEW = 'UiSrcMapButSatelliteClk';
export const GTM_CLICK_SEARCH_PAGE_STREET_VIEW = 'UiSrcMapButStreetClk';

// Quebec Popup
export const GTM_CLICK_QUEBEC_POPUP_HOME_STAY = 'UiHomepMdlButQBStayClk';
export const GTM_CLICK_QUEBEC_POPUP_HOME_LEAVE = 'UiHomepMdlButQBLeaveClk';
export const GTM_CLICK_QUEBEC_POPUP_HOME_CLOSE = 'UiHomepMdlButQBCloseClk';

export const GTM_CLICK_QUEBEC_POPUP_AREA_STAY = 'UiAreaMdlButQBStayClk';
export const GTM_CLICK_QUEBEC_POPUP_AREA_LEAVE = 'UiAreaMdlButQBLeaveClk';
export const GTM_CLICK_QUEBEC_POPUP_AREA_CLOSE = 'UiAreaMdlButQBCloseClk';

export const GTM_CLICK_QUEBEC_POPUP_MAP_STAY = 'UiSrcMdlButQBStayClk';
export const GTM_CLICK_QUEBEC_POPUP_MAP_LEAVE = 'UiSrcMdlButQBLeaveClk';
export const GTM_CLICK_QUEBEC_POPUP_MAP_CLOSE = 'UiSrcMdlButQBCloseClk';

// Home Appraisal
export const GTM_CLICK_APPRAISAL_HOME_SEARCH_BAR = 'UiEstPNavSearchBarClk';
export const GTM_CLICK_APPRAISAL_HOME_SEARCH_RESULT = 'UiEstPButSearchClk';
export const GTM_CLICK_APPRAISAL_BACK_TO_SEARCH = 'UiEstPButBackClk';
export const GTM_CLICK_APPRAISAL_ESTIMATE = 'UiEstPButGetEstClk';
export const GTM_CLICK_APPRAISAL_EDIT_PROPERTY_DETAILS = 'UiEstRTxtEditClk';
export const GTM_CLICK_APPRAISAL_TALK_TO_AGENT = 'UiEstRTxtAgentClk';
export const GTM_CLICK_APPRAISAL_ENTER_EMAIL = 'UiEstRTxtEmailClk';
export const GTM_CLICK_APPRAISAL_DETAIL_ESTIMATE_FOR_FREE = 'UiEstRButEstClk';
export const GTM_CLICK_APPRAISAL_SIMILAR_AVAILABLE_CARD = 'UiEstRCardSaleClk';
export const GTM_CLICK_APPRAISAL_SIMILAR_SOLD_CARD = 'UiEstRCardSoldClk';
export const GTM_CLICK_APPRAISAL_DETAIL_ESTIMATE_FOR_FREE_POPUP = 'UiEstRMdlButEstClk';
export const GTM_CLICK_APPRAISAL_SPEAK_TO_AGENT = 'UiEstErrButEstClk';
export const GTM_CLICK_APPRAISAL_NO_ESTIMATE_BACK_TO_SEARCH = 'UiEstErrButBackClk';
export const GTM_CLICK_APPRAISAL_NO_ESTIMATE_SIMILAR_SOLD_CARD = 'UiEstErrCardSoldClk';
export const GTM_CLICK_APPRAISAL_NO_ESTIMATE_SIMILAR_AVAILABLE_CARD = 'UiEstErrCardSaleClk';
export const GTM_CLICK_APPRAISAL_NO_ESTIMATE_DETAIL_ESTIMATE_FOR_FREE_POPUP = 'UiEstErrMdlButEstClk';
export const GTM_CLICK_APPRAISAL_GOOGLE_ADDRESS = 'UiEstPTxtGoogleAddressClk';
export const GTM_CLICK_APPRAISAL_LISTING_ADDRESS = 'UiEstPTxtListingAddressClk';

// ------- View events --------
// Address Page:
export const GTM_VIEW_ADDRESS_PAGE_KEY_FACTS_TAB = 'UiAddressTabKeyFactsView';
export const GTM_VIEW_ADDRESS_PAGE_PRICE_HISTORY_TAB = 'UiAddressTabPriceHisView';
export const GTM_VIEW_ADDRESS_PAGE_AVERAGE_SOLD_PRICE_TAB = 'UiAddressTabSoldDataView';
export const GTM_VIEW_ADDRESS_PAGE_SCHOOLS_TAB = 'UiAddressTabSchoolsView';
export const GTM_VIEW_ADDRESS_PAGE_ZOOPRAISAL = 'UiAddressTxtZoopraView';
export const GTM_VIEW_ADDRESS_PAGE_SOLD_LISTING = 'UiAddressSoldView';
export const GTM_VIEW_ADDRESS_PAGE_FOR_SALE_LISTING = 'UiAddressSaleView';
export const GTM_VIEW_ADDRESS_PAGE_INACTIVE_LISTING = 'UiAddressInactiveView';
export const GTM_VIEW_ADDRESS_PAGE_STANDARD_LISTING = 'UiAddressEmptyView';
export const GTM_VIEW_ADDRESS_PAGE_SOLD_LISTING_MODAL = 'UiAddressMdlSoldView';
export const GTM_VIEW_ADDRESS_PAGE_FOR_SALE_LISTING_MODAL = 'UiAddressMdlForSaleView';
export const GTM_VIEW_ADDRESS_PAGE_INACTIVE_LISTING_MODAL = 'UiAddressMdlInactiveView';

// Home Appraisal
export const GTM_VIEW_APPRAISAL_HOME = 'UiEstPView';
export const GTM_VIEW_APPRAISAL_ESTIMATE = 'UiEstRView';
export const GTM_VIEW_APPRAISAL_NO_ESTIMATE = 'UiEstErrView';

// ------- Auth events -------
// Sign up:
export const GTM_AUTH_SIGN_UP_NORMAL_EMAIL = 'UiAllAuthCodeEmailSignup';
export const GTM_AUTH_SIGN_UP_GOOGLE = 'UiAllAuthCodeGoogSignup';
export const GTM_AUTH_SIGN_UP_FACEBOOK = 'UiAllAuthCodeFbookSignup';
