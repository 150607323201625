import { statusOptions } from 'utils/select-options';
import { dasherize } from '@zoocasa/node-kit/strings/dasherize';
import { getQueryParams } from './update-query-params';
import setNestedProperty from 'set-value';
import { Locker, Status, HomeTypeFilter, ListingParams, Sort, Filter, STATUS_KEYS, LOCKER_VALUES, SORT_KEYS, DATE_DESCENDING_SORT } from 'contexts/preferences/listing-params/types';

import type { PartialDeep } from 'type-fest';

export function parameterContainsIsMore(parameter: string) {
  return `${parameter}`.includes('+');
}

export const toggleContainsMore = (value: string) => {
  return parameterContainsIsMore(value) ? value.replace('+', '') : `${value}+`;
};

export function isHouse(homeTypeFilter: HomeTypeFilter) {
  return homeTypeFilter.houseDetached || homeTypeFilter.houseSemidetached || homeTypeFilter.houseAttached;
}

export function getHomeTypeSelection(homeTypeFilter: HomeTypeFilter) {
  const { townhouse, condo } = homeTypeFilter;
  const house = isHouse(homeTypeFilter);
  if (house && !condo && !townhouse) {
    return 'Houses';
  } else if (condo && !house && !townhouse) {
    return 'Condos';
  } else if (townhouse && !house && !condo) {
    return 'Townhouses';
  } else {
    return 'Homes';
  }
}

export function isForSaleOrRent(rentalFilter: boolean) {
  return rentalFilter ? 'for Rent' : 'for Sale';
}

export function getNumberOfHomeTypesFiltered(homeTypeFilter: HomeTypeFilter) {
  let numberOfHomesFiltered = 0;
  if (isHouse(homeTypeFilter)) {
    numberOfHomesFiltered += 1;
  }
  if (homeTypeFilter.townhouse) {
    numberOfHomesFiltered += 1;
  }
  if (homeTypeFilter.condo) {
    numberOfHomesFiltered += 1;
  }
  return numberOfHomesFiltered;
}

export function getHomeTypesFilteredLabel(homeTypeFilter: HomeTypeFilter) {
  const label = [];
  if (isHouse(homeTypeFilter)) {
    label.push('House');
  }
  if (homeTypeFilter.townhouse) {
    label.push('Townhouse');
  }
  if (homeTypeFilter.condo) {
    label.push(label.length > 1 ? 'Condo/Apt' : 'Condo/Apartment');
  }
  return label.length ? label.join(', ') : 'Any';
}

export function getLabelFromFilterObject(filterObject: any) {
  const labels = [];

  for (const property in filterObject) {
    if (filterObject[property]) {
      const formattedProperty = property
        .replace(/([a-z])([A-Z])/g, '$1 $2'); // Add space between camelCase words
      labels.push(formattedProperty);
    }
  }

  if (labels.length === 0) {
    return 'Any';
  }

  let result = labels.join(', ');

  if (result.length > 25) {
    result = result.slice(0, 22) + '...';
  }

  return result;
}

export function getListingStatusLabel(status: Status, isRental: boolean) {
  const statusOption = statusOptions(isRental).find(item => item.value === status);
  return statusOption ? statusOption.label : 'Unknown';
}

export function getBedOrBathSelection(secondaryFilter?: string) {
  if (secondaryFilter && (secondaryFilter.includes('-bedroom') || secondaryFilter.includes('-bathroom'))) {
    return `${secondaryFilter.replace(/-/g, ' ')} `;
  } 
  return '';
}

export function getOtherSecondaryFilterSelection(secondaryFilter?: string) {
  if (secondaryFilter && !(secondaryFilter.includes('-bedroom') || secondaryFilter.includes('-bathroom') || secondaryFilter.includes('filter'))) {
    return ` ${secondaryFilter.replace(/-/g, ' ')}`;
  } 
  return '';
}

// https://stackoverflow.com/questions/6566456/how-to-serialize-an-object-into-a-list-of-url-query-parameters
export function formatQueryIntoURLQueryParams(query: Record<string, unknown> | { latitude: number; longitude: number }) {
  const flattenedObject = flattenObject(query);
  let queryParameterString = '';
  for (const key in flattenedObject) {
    if (flattenedObject[key] !== null) {
      if (queryParameterString != '') {
        queryParameterString += '&';
      }
      const originalKey = key;
      let formattedKey;
      if (originalKey === 'houseAttached' || originalKey === 'houseDetached' || originalKey === 'houseSemidetached') {
        // In the past we made these shortened form in our url (i.e. houseattached = attached), at this
        // point we need to maintain that mapping or we'll break bookmarks for our users :/
        formattedKey = key.replace('house', '').toLowerCase();
      } else {
        formattedKey = dasherize(key).replace('+', '').toLowerCase();
      }
      queryParameterString += formattedKey + '=' + encodeURIComponent(flattenedObject[originalKey] as string | boolean | number);
    }
  }
  return queryParameterString;
}

function extractBooleanParam(value: string | undefined, defaultValue: boolean) {
  if (value === '' || value === undefined) {
    return defaultValue;
  }
  return value.toLowerCase() === 'true';
}

function extractStringParam<T extends string | number>(value: string | undefined, defaultValue: T) {
  if (value === '' || value === undefined) {
    return defaultValue;
  }
  return value as T;
}

function extractNumberParam<T extends number | null>(value: string | undefined, defaultValue: T) {
  if (value === '' || value === undefined) {
    return defaultValue;
  }
  return Number(value);
}

/**
 * Parses the given url search params string and try to create a valid Filter object.
 * 
 * **Note**: `Unknown` and `out of range` values will be ignored. Does not set default values to the parsed Filter object.
 * 
 * @param urlSearchParams A string, which will be parsed from `application/x-www-form-urlencoded` format. A leading `'?'` character is ignored.
 * @returns A PartialDeep<Filter> object based on the given urlSearchParams. 
 */
export function getFilterFromURLSearchParams(urlSearchParams: URLSearchParams): PartialDeep<Filter> {
  const filter: PartialDeep<Filter> = {};

  if (urlSearchParams.has('rental')) {
    filter.rental = urlSearchParams.get('rental')?.toLowerCase() == 'true' ;
  }

  if (urlSearchParams.has('garage')) {
    filter.garage = urlSearchParams.get('garage')?.toLowerCase() == 'true' ;
  }

  if (urlSearchParams.has('open-house')) {
    filter.openHouse = urlSearchParams.get('open-house')?.toLowerCase() == 'true' ;
  }

  if (urlSearchParams.has('pool')) {
    filter.pool = urlSearchParams.get('pool')?.toLowerCase() == 'true' ;
  }

  if (urlSearchParams.has('fireplace')) {
    filter.fireplace = urlSearchParams.get('fireplace')?.toLowerCase() == 'true' ;
  }

  if (urlSearchParams.has('waterfront')) {
    filter.waterfront = urlSearchParams.get('waterfront')?.toLowerCase() == 'true' ;
  }

  if (urlSearchParams.has('detached')) {
    setNestedProperty(filter, 'homeType.houseDetached', urlSearchParams.get('detached')?.toLowerCase() == 'true');
  }
 
  if (urlSearchParams.has('semidetached')) {
    setNestedProperty(filter, 'homeType.houseSemidetached', urlSearchParams.get('semidetached')?.toLowerCase() == 'true');
  }

  if (urlSearchParams.has('attached')) {
    setNestedProperty(filter, 'homeType.houseAttached', urlSearchParams.get('attached')?.toLowerCase() == 'true');
  }

  if (urlSearchParams.has('townhouse')) {
    setNestedProperty(filter, 'homeType.townhouse', urlSearchParams.get('townhouse')?.toLowerCase() == 'true');
  }

  if (urlSearchParams.has('condo')) {
    setNestedProperty(filter, 'homeType.condo', urlSearchParams.get('condo')?.toLowerCase() == 'true');
  }

  if (urlSearchParams.has('single-family')) {
    setNestedProperty(filter, 'additional.house.singleFamily', urlSearchParams.get('single-family')?.toLowerCase() == 'true');
  }

  if (urlSearchParams.has('basement-apartment')) {
    setNestedProperty(filter, 'additional.house.basementApartment', urlSearchParams.get('basement-apartment')?.toLowerCase() == 'true');
  }

  if (urlSearchParams.has('basement-apartment')) {
    setNestedProperty(filter, 'additional.house.basementApartment', urlSearchParams.get('basement-apartment')?.toLowerCase() == 'true');
  }

  if (urlSearchParams.has('duplex')) {
    setNestedProperty(filter, 'additional.house.duplex', urlSearchParams.get('duplex')?.toLowerCase() == 'true');
  }

  if (urlSearchParams.has('triplex')) {
    setNestedProperty(filter, 'additional.house.triplex', urlSearchParams.get('triplex')?.toLowerCase() == 'true');
  }

  if (urlSearchParams.has('fourplex')) {
    setNestedProperty(filter, 'additional.house.fourplex+', urlSearchParams.get('fourplex')?.toLowerCase() == 'true');
  }

  if (urlSearchParams.has('locker') && LOCKER_VALUES.includes(urlSearchParams.get('locker') || '')) {
    setNestedProperty(filter, 'additional.condoOrTownhouse.locker', urlSearchParams.get('locker') as Locker);
  }

  if (urlSearchParams.has('maintenance-fee')) {
    setNestedProperty(filter, 'additional.condoOrTownhouse.maintenanceFee', Number(urlSearchParams.get('maintenance-fee')));
  }

  if (urlSearchParams.has('status') && STATUS_KEYS.includes(urlSearchParams.get('status') || '')) {
    filter.status = urlSearchParams.get('status') as Status;
  }

  if (urlSearchParams.has('price-min') && urlSearchParams.get('price-min')?.trim()?.length !== 0) {
    filter.priceMin = Number(urlSearchParams.get('price-min'));
  }

  if (urlSearchParams.has('price-max') && urlSearchParams.get('price-max')?.trim()?.length !== 0) {
    filter.priceMax = Number(urlSearchParams.get('price-max'));
  }

  if (urlSearchParams.has('sqft-min') && urlSearchParams.get('sqft-min')?.trim()?.length !== 0) {
    filter.sqftMin = Number(urlSearchParams.get('sqft-min'));
  }

  if (urlSearchParams.has('sqft-max') && urlSearchParams.get('sqft-max')?.trim()?.length !== 0) {
    filter.sqftMax = Number(urlSearchParams.get('sqft-max'));
  }

  if (urlSearchParams.has('listed-since') && urlSearchParams.get('listed-since')?.trim()?.length !== 0) {
    filter.listedSince = urlSearchParams.get('listed-since');
  }

  if (urlSearchParams.has('listed-to') && urlSearchParams.get('listed-to')?.trim()?.length !== 0) {
    filter.listedTo = urlSearchParams.get('listed-to');
  }

  const regex = new RegExp(/^[0-6]\+?$/);

  if (urlSearchParams.has('bathrooms') && urlSearchParams.get('bathrooms')?.trim()?.length !== 0 && regex.test(urlSearchParams.get('bathrooms') as string)) {
    filter.bathrooms = urlSearchParams.get('bathrooms') as string;
  }

  if (urlSearchParams.has('bedrooms') && urlSearchParams.get('bedrooms')?.trim()?.length !== 0 && regex.test(urlSearchParams.get('bedrooms') as string)) {
    filter.bedrooms = urlSearchParams.get('bedrooms') as string;
  }

  if (urlSearchParams.has('parking-spaces') && urlSearchParams.get('parking-spaces')?.trim()?.length !== 0 && regex.test(urlSearchParams.get('parking-spaces') as string)) {
    filter.parkingSpaces = urlSearchParams.get('parking-spaces') as string;
  }

  return filter;
}
/**
 * Parses the given url search params string for the sorting value.
 * 
 * **Note**: `Unknown` values will be ignored and the default sort value (`DATE_DESCENDING_SORT`) is returned instead.
 * 
 * @param urlSearchParams A string, which will be parsed from `application/x-www-form-urlencoded` format. A leading `'?'` character is ignored.
 * @returns the Sort value based on the given search params string. `DATE_DESCENDING_SORT` is returned when no sorting is set or `Unknown` are found.
 */
export function getSortFromURLSearchParams(urlSearchParams: URLSearchParams): Sort {
  if (urlSearchParams.has('sort') && SORT_KEYS.includes(urlSearchParams.get('sort') || '')) {
    return urlSearchParams.get('sort') as Sort;
  }
  return DATE_DESCENDING_SORT;
}

/**
 * Parses the given url search params string for the slug-fallback value.
 * 
 * @param urlSearchParams A string, which will be parsed from `application/x-www-form-urlencoded` format. A leading `'?'` character is ignored.
 * @returns the slug-fallback value based on the given search params string. `null` is returned when no slug-fallback is set.
 */
export function getSlugFallbackFromURLSearchParams(urlSearchParams: URLSearchParams): string | null {
  return urlSearchParams.get('slug-fallback');
}

export function getFilterParamsFromParsedUrlQuery(query: NodeJS.Dict<string> ): ListingParams {
  return {
    sort: extractStringParam<Sort>(query.sort, '-date'),
    filter: {
      rental: extractBooleanParam(query.rental, false),
      status: extractStringParam<Status>(query.status, 'available'),
      slug: query.slug || 'toronto-on',
      latitude: extractNumberParam(query.latitude, 43.653226),
      longitude: extractNumberParam(query.longitude, -79.3831843),
      zoom: extractNumberParam(query.zoom, 14),
      homeType: {
        houseDetached: extractBooleanParam(query['detached'], true),
        houseSemidetached: extractBooleanParam(query['semidetached'], true),
        houseAttached: extractBooleanParam(query['attached'], true),
        townhouse: extractBooleanParam(query.townhouse, true),
        condo: extractBooleanParam(query.condo, true),
      },
      priceMin: extractNumberParam(query['price-min'], null),
      priceMax: extractNumberParam(query['price-max'], null),
      listedSince: query['listed-since'] || null,
      listedTo: query['listed-to'] || null,
      bedrooms: query.bedrooms || '0+',
      sqftMin: extractNumberParam(query['sqft-min'], null),
      sqftMax: extractNumberParam(query['sqft-max'], null),
      bathrooms: extractStringParam(query.bathrooms, '1+'),
      parkingSpaces: extractStringParam(query['parking-spaces'], '0+'),
      openHouse: extractBooleanParam(query['open-house'], false),
      garage: extractBooleanParam(query.garage, false),
      pool: extractBooleanParam(query.pool, false),
      fireplace: extractBooleanParam(query.fireplace, false),
      waterfront: extractBooleanParam(query.waterfront, false),
      additional: {
        house: {
          singleFamily: extractBooleanParam(query['single-family'], false),
          basementApartment: extractBooleanParam(query['basement-apartment'], false),
          duplex: extractBooleanParam(query.duplex, false),
          triplex: extractBooleanParam(query.triplex, false),
          'fourplex+': extractBooleanParam(query.fourplex, false),
        },
        condoOrTownhouse: {
          locker: extractStringParam<Locker>(query.locker, 'any'),
          maintenanceFee: extractNumberParam(query['maintenance-fee'], null),
        },
      },
      areaName: query['area-name'] || 'Toronto, ON',
      boundary: query.boundary || null,
      providerId: query.providerId || null,
    },
  };
}

export function getFilterParamsFromURLQuery(urlParams: string): ListingParams {
  const query = getQueryParams(urlParams) as any;
  return getFilterParamsFromParsedUrlQuery(query);
}

export function convertFilterAndPositionToUrlString(listingParams: ListingParams, latitude: number, longitude: number, zoom: number) {
  const param = {
    sort: listingParams.sort,
    rental: listingParams.filter.rental,
    status: listingParams.filter.status,
    slug: listingParams.filter.slug || 'toronto-on',
    latitude,
    longitude,
    zoom,
    detached: listingParams.filter.homeType.houseDetached,
    semidetached: listingParams.filter.homeType.houseSemidetached,
    attached: listingParams.filter.homeType.houseAttached,
    townhouse: listingParams.filter.homeType.townhouse,
    condo: listingParams.filter.homeType.condo,
    'price-min': listingParams.filter.priceMin,
    'price-max': listingParams.filter.priceMax,
    'listed-since': listingParams.filter.listedSince,
    'listed-to': listingParams.filter.listedTo,
    bedrooms: listingParams.filter.bedrooms,
    'sqft-min': listingParams.filter.sqftMin,
    'sqft-max': listingParams.filter.sqftMax,
    bathrooms: listingParams.filter.bathrooms,
    'parking-spaces': listingParams.filter.parkingSpaces,
    'open-house': listingParams.filter.openHouse,
    garage: listingParams.filter.garage,
    pool: listingParams.filter.pool,
    fireplace: listingParams.filter.fireplace,
    waterfront: listingParams.filter.waterfront,
    'single-family': listingParams.filter.additional.house.singleFamily,
    'basement-apartment': listingParams.filter.additional.house.basementApartment,
    duplex: listingParams.filter.additional.house.duplex,
    triplex: listingParams.filter.additional.house.triplex,
    'fourplex+': listingParams.filter.additional.house['fourplex+'],
    locker: listingParams.filter.additional.condoOrTownhouse.locker,
    'maintenance-fee': listingParams.filter.additional.condoOrTownhouse.maintenanceFee,
    boundary: listingParams.filter.boundary,
    'area-name': listingParams.filter.areaName,
  };

  // delete null values
  Object.keys(param).forEach((k: any) => {
    (param as any)[k] == null && delete (param as any)[k];
  });
  return param;
}

// https://stackoverflow.com/questions/33036487/one-liner-to-flatten-nested-object
function flattenObject(query: Record<string, unknown>) {
  const flattenedObject: Record<string, unknown> = {};
  Object.keys(query).forEach(key => {
    if (typeof query[key] === 'object' && query[key] !== null) {
      Object.assign(flattenedObject, flattenObject(query[key] as Record<string, unknown>));
    } else {
      flattenedObject[key] = query[key];
    }
  });
  return flattenedObject;
}
