import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import { FeaturesContext, IFeatures } from 'contexts/features';
import Link from 'components/link';
import ImageLoader from 'components/image-loader';
import TwitterIcon from 'components/icon/twitter-icon';
import FacebookIcon from 'components/icon/facebook-icon';
import InstagramIcon from 'components/icon/instagram-icon';
import LinkedInIcon from 'components/icon/linkedin-icon';
import MailIcon from 'components/icon/mail-icon';
import PhoneIcon from 'components/icon/phone-icon';
import YoutubeIcon from 'components/icon/youtube-icon';
import styles from './expRealtyCaFooterStyle.module.scss';
import { useThemeContext } from 'contexts/theme';
import { useUser } from 'contexts';
import { buildClassName } from 'utils/build-class-name';
import { ThemeConfig } from 'themes';
import Button, { SECONDARY_THEME } from 'components/control/button';

export default function ExpRealtyCaFooter() {
  const { features } = useContext(FeaturesContext) as IFeatures;
  const { theme } = useThemeContext();
  const { siteLocation } = useUser();
  const router = useRouter();
  const isActive = router.route.match(/^((?!\/team\/|\/search).)*$/);
  const address = theme.organization.address(siteLocation);

  const featureFlags = () => {
    if (features['footerFeatures']) {
      return Object.keys(features).map(key => {
        if (Object.prototype.hasOwnProperty.call(features, key)) {
          return <p key={key} className={styles['feature-flags']}>{key}: {(features as unknown as Record<string, string>)[key].toString()}</p>;
        }
      });
    }
  };

  const featureUI = <div className={styles.features}>{featureFlags()}</div>;

  return !isActive ? null :
    <div className={styles.component}>
      <div className={styles['footer-top']}>
        <div className={styles['footer-top-left']}>
          <div className={styles['footer-links']}>
            <div className={buildClassName(styles.block, styles.block1)}>
              <Column1/>
            </div>
            <div className={buildClassName(styles.block, styles.block2)}>
              <Column2/>
            </div>
            <div className={buildClassName(styles.block, styles.block3)}>
              <Column3/>
            </div>
            <div className={buildClassName(styles.block, styles.block4)}>
              <p className={styles['office-address']}>
                {address}
              </p>
              <EmailPhoneNumberContacts theme={theme}/>
              <div className={styles.socials}>
                <a href={theme.organization.twitterUrl} target="_blank" rel="noreferrer"><TwitterIcon isExpTheme className={styles['social-x-icon']}/></a>
                <a href={theme.organization.facebookUrl} target="_blank" rel="noreferrer"><FacebookIcon isExpTheme className={styles['social-x-icon']}/></a>
                <a href={theme.organization.linkedinUrl} target="_blank" rel="noreferrer"><LinkedInIcon isExpTheme className={styles['social-x-icon']}/></a>
                <a href={theme.organization.instagramUrl} target="_blank" rel="noreferrer"><InstagramIcon isExpTheme className={styles['social-ig-icon']}/></a>
                <a href={theme.organization.youtubeUrl} target="_blank" rel="noreferrer"><YoutubeIcon className={styles['social-ig-icon']}/></a>
              </div>
            </div>
          </div>
          <div className={styles['footer-big-text']}>
            <p>
              eXp REALTY
            </p>
            <div className={buildClassName(styles['join-section-mobile-container'])}>
              <JoinSection/>
            </div>
          </div>
        </div>
        <div className={buildClassName(styles['footer-top-right'])}>
          <JoinSection/>
        </div>
      </div>
      <div className={styles['footer-bottom']}>
        <p>The trademarks MLS®, Multiple Listing Service® and the associated logos are owned
          by The Canadian Real Estate Association (CREA) and identify the quality of services provided by real estate
          professionals who are members of CREA. eXp Realty holds real estate brokerage licenses in multiple provinces. For information on licences please
          contact us at info@exprealty.net</p>
        <br/>
        <p>For listings in Canada, the trademarks REALTOR®, REALTORS®, and the REALTOR® logo are controlled by The
          Canadian Real Estate Association (CREA) and identify real estate professionals who are members of CREA. The
          trademarks MLS®, Multiple Listing Service® and the associated logos are owned by CREA and identify the
          quality
          of services provided by real estate professionals who are members of CREA. Used under license.</p>
        <br/>
        <ExpDynamicCopyright/>
      </div>
      {featureUI}
    </div>;
}

const ExpDynamicCopyright = () =>
  <p> © {new Date().getFullYear()} eXp Realty. eXp World Holdings, Inc. All Rights Reserved</p>;

const EmailPhoneNumberContacts = ({ theme }: { theme: ThemeConfig }) => {
  const phoneNumber = theme.organization.phoneNumber();
  const emailAddress = theme.organization.email();

  return (
    <section className={styles.contact}>
      {phoneNumber && (
        <div className={styles['contact-item']}>
          <a href={`tel:${phoneNumber}`}>
            <PhoneIcon/>
            <p>
              {phoneNumber}
            </p>
          </a>
        </div>
      )}
      {emailAddress && (
        <div className={styles['contact-item']}>
          <a href={`mailto:${emailAddress}`}>
            <MailIcon/>
            <p>
              {emailAddress}
            </p>
          </a>
        </div>
      )}
    </section>
  );
};

const Column1 = () => {
  return (
    <div className={styles['about-exp-links']}>
      <Link href="/explore-exp">Explore eXp</Link>
      <Link href="/agents-search">Find an Agent</Link>
      <Link href="/join-exp">Join eXp</Link>
      <a href="https://expworldholdings.com/" target="_blank">Investor Relations</a>
      <a href="https://expworldholdings.com/careers/" target="_blank">Company Careers</a>
    </div>
  );
};
const Column2 = () => {
  return (
    <div className={styles['about-exp-links']}>
      <a href="https://life.exprealty.com/" target="_blank">eXp Life</a>
      <a href="https://zoocasa.com/" target="_blank">Zoocasa</a>
      <a href="https://www.success.com/" target="_blank">Success</a>
      <a href="https://expquebec.com/" target="_blank">Quebec Listings</a>
    </div>
  );
};
const Column3 = () => {
  return (
    <div className={buildClassName(styles['about-exp-links'], styles['terms-and-conditions'])}>
      <a href="https://expworldholdings.com/terms-of-service/" target="_blank">Terms and Conditions</a>
      <a href="https://expworldholdings.com/terms-of-service-fr/" target="_blank">Modalités</a>
      <a href="https://expworldholdings.com/privacy-policy/" target="_blank">Privacy Policy</a>
      <a href="https://expworldholdings.com/privacy-policy-fr/" target="_blank">Politique de confidentialité</a>
      <a href="https://expworldholdings.com/cookie-policy/" target="_blank">Cookie Policy</a>
    </div>
  );
};
const JoinSection = () => {
  return (
    <div className={styles['join-section']}>
      <div className={styles['join-image-container']}>
        <ImageLoader src="/next/assets/images/exp-footer-pic.png" alt="eXp Realty"/>
      </div>
      <Link href="/join-exp" target='_blank' className={styles['join-link']}>
        <Button theme={SECONDARY_THEME} label={'Join Us'} className={styles['join-button']} />
      </Link>
    </div>
  );
};
