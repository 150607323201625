import TagManager from 'react-gtm-module';
import { removeSearchParam } from './update-query-params';

export function trackCurrentPageView() {
  const locationObj = window.location;
  const pixelPagePath = removeSearchParam(removeSearchParam(locationObj.href, 'latitude'), 'longitude');
  
  TagManager.dataLayer({
    dataLayer: {
      event: 'Pageview',
      pagePath: locationObj.pathname,
      pixelPagePath,
    },
  });
}

export function trackDelayedPageView() {
  TagManager.dataLayer({
    dataLayer: {
      event: 'delayed-pageview',
      pagePath: window.location.pathname,
    },
  });
}

export function trackConnectionRequest() {
  TagManager.dataLayer({
    dataLayer: {
      event: 'Pageview',
      pagePath: '/connection-request-submission',
    },
  });
}

export function trackOffMarketPage() {
  TagManager.dataLayer({
    dataLayer: {
      event: 'off-market-pageview',
      pagePath: window.location.pathname,
    },
  });
}

export function trackWebVitals(name: string, value: number) {
  TagManager.dataLayer({
    dataLayer: {
      event: name,
      category: 'Web Vitals',
      pagePath: window.location.pathname,
      value: value,
    },
  });
}

export function trackEvent(event: string, category = 'All', label?: string, value?: number) {
  TagManager.dataLayer({ dataLayer: { event, category, label, value }});
}

export function trackGrowthbookEvent(experimentId: string, variantionId: number) {
  TagManager.dataLayer({ 
    dataLayer: {
      event: 'experiment_viewed',
      event_category: 'experiment',
      experiment_id: experimentId,
      variation_id: variantionId,
    },
  });
}