import ExprealtyCaTheme from './exprealtyCA';
import ZoocasaTheme from './zoocasa';

export type { ThemeConfig } from './themeConfig';

export const themes = {
  zoocasa: ZoocasaTheme,
  exprealtyCA: ExprealtyCaTheme,
  exprealtyUS: ExprealtyCaTheme,
};

export type ThemeName = keyof typeof themes;
