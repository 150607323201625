export enum PagesList {
  BUY_WITH_ZOOCASA = 'buy-with-zoocasa',
  SELL_WITH_ZOOCASA = 'sell-with-zoocasa',
  HOME_APPRAISAL = 'home-appraisal',
}

export type Pages =
  PagesList.BUY_WITH_ZOOCASA | 
  PagesList.SELL_WITH_ZOOCASA |
  PagesList.HOME_APPRAISAL;
