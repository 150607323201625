import { loadScript } from 'utils/load-file';

async function getGeocoder() {
  const noGoogleMap = !(window as any).google || !(window as any).google.maps;
  if (noGoogleMap) {
    await loadScript({ source: `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY || ''}&libraries=places&callback=initGoogle` });
  }

  if (!google) {
    return undefined;
  }

  return new google.maps.Geocoder();
}

function createGeocoderRequest(
  placeId: string) {
  const request: google.maps.GeocoderRequest = { placeId: placeId };
  return request;
}

export default async function geocode(placeId: string) {

  const geocoder = await getGeocoder();

  if (geocoder) {
    const request = createGeocoderRequest(placeId);
    const response = await geocoder.geocode(request);

    if (response?.results) {
      return response.results[0];
    }
      
  }

  return undefined;
}
  