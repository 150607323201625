import { transitionZoomLevel } from 'utils/apple-maps';
import { SEARCH_PREDICTION_SOURCE_GOOGLE } from './google-maps/types';

export default function determineZoomLevel(prediction: any) {
  let zoom = transitionZoomLevel;

  if (prediction.label === 'neighbourhood') {
    zoom = 15;
  }

  if (prediction.source === SEARCH_PREDICTION_SOURCE_GOOGLE && prediction.label === 'street') {
    zoom = 16;
  }

  if (prediction.source === 'apple') {
    // The number of commas is a rough estimation of the specificity of the result
    // More commas, the more specific the adddress, the closer zoomed in the user should be.
    const numberOfCommas = prediction.description.replace(/[^,]/g, '').length;
    if (numberOfCommas > 2) {
      zoom = 16;
    } else if (numberOfCommas <= 2) {
      zoom = 13;
    }
  }
  return zoom;
}
