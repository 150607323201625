import geojsonPolygonFromMapkitBbox from './geojson-polygon-from-mapkit-bbox';
import mapkitBoundaryFromGeojsonPolygon from './mapkit-boundary-from-geojson-polygon';
import mapkitPositionFromGeojsonPoint from './mapkit-position-from-geojson-point';
import calculatePoint from '../../box-positioner/calculate-point';

import type { Position, GeoJsonPolygon, ZoomType, PopupPosition, PinPos, Pin, Offsets } from '../types';
import type { PolylineOverlay, Map, MapkitMappable, MarkerOptions, OverlayStyles, DrawMapOptions } from './types';

export type AnnotationFactoryType = (coordinate: mapkit.Coordinate, options: mapkit.AnnotationConstructorOptions) => HTMLElement;
export type AnnotationListenerType = (event: mapkit.EventBase<mapkit.Annotation>) => void;

export default class AppleMap {
  mapElementId!: string;
  map!: Map;
  lineOverlay!: mapkit.PolylineOverlay;

  removeIdleListener?: () => void;
  removeZoomListener?: () => void;
  removeCenterListener?: () => void;

  drawMap(options: DrawMapOptions, mapElement: string) {
    const { zoom = 14, latitude = 43.653226, longitude = -79.3831843, showsMapTypeControl = false, showsZoomControls = false, isScrollEnabled = true, isRotationEnabled = true } = options;
    this.mapElementId = mapElement;
    this.map = new mapkit.Map(mapElement, {
      center: new mapkit.Coordinate(latitude, longitude),
      showsMapTypeControl: showsMapTypeControl,
      showsCompass: mapkit.FeatureVisibility.Hidden,
      showsZoomControl: showsZoomControls,
      isScrollEnabled: isScrollEnabled,
      isRotationEnabled: isRotationEnabled,
    }) as Map;

    this.map._impl.zoomLevel = zoom;
    this.map._allowWheelToZoom = true;
  }

  createPin(latitude: number, longitude: number, type?: string): Pin {
    type = type ? type : 'pin';
    return {
      latitude,
      longitude,
      pinElement: document.createElement(type),
    };
  }

  addAnnotation(factory: AnnotationFactoryType, pinData: any, calloutDelegate: mapkit.AnnotationCalloutDelegate, position: Position, appearanceAnimation: string, listener: AnnotationListenerType) {
    const coordinate = new mapkit.Coordinate(position.lat, position.lng);
    const annotation = new mapkit.Annotation(coordinate, factory, {
      appearanceAnimation, 
      data: pinData,
      callout: calloutDelegate,
    });
    annotation.addEventListener('select', listener as any);
    annotation.addEventListener('deselect', listener as any);

    return this.map.addAnnotation(annotation);
  }

  addPin(mappable: MapkitMappable, showHover: (event?: any) => void, removeHover: () => void): void {
    const coordinate = new mapkit.Coordinate(mappable.latitude, mappable.longitude);
    const factory = () => {
      const pin = mappable.pinElement;
      pin.addEventListener('mouseenter', showHover);
      pin.addEventListener('touchend', showHover);
      pin.addEventListener('mouseleave', removeHover);

      return pin;
    };
    const pinAnnotation = new mapkit.Annotation(coordinate, factory, {});
    this.map.addAnnotation(pinAnnotation);
    mappable.pinAnnotation = pinAnnotation;

    this.setRemoveEventListenerCallbacks(mappable, showHover, removeHover);
  }

  onZoom(zoomListener: (event?: any) => void) {
    this.map.addEventListener('zoom-end', zoomListener);
    this.removeZoomListener = () => {
      this.map.removeEventListener('zoom-end', zoomListener);
    };
  }

  onPan(panListener: (event?: any) => void) {
    this.map.addEventListener('region-change-end', panListener);
    this.removeIdleListener = () => {
      this.map.removeEventListener('region-change-end', panListener);
    };
  }

  removeEventListeners() {
    if (this.removeZoomListener) {
      this.removeZoomListener();
    }
    if (this.removeIdleListener) {
      this.removeIdleListener();
    }
  }

  boundaryFromGeoJsonPolygon(boundary?: GeoJsonPolygon) {
    return mapkitBoundaryFromGeojsonPolygon(boundary);
  }

  removeOverlay(hoverBoundaryMapPolygon: mapkit.Overlay): void {
    this.map.removeOverlay(hoverBoundaryMapPolygon);
  }

  zoomIn(): void {
    this.map.setRegionAnimated(this.calculateZoomRegion('in'));
  }

  zoomOut(): void {
    this.map.setRegionAnimated(this.calculateZoomRegion('out'));
  }

  makePolygon(paths: mapkit.Coordinate[]) {
    const style = new mapkit.Style({
      strokeColor: '#5A5C65',
      strokeOpacity: 0.9,
      lineWidth: 2,
      fillColor: '#5A5C65',
      fillOpacity: 0.2,
    });
    const polygonOptions = { style };
    const polygonOverlay = new mapkit.PolygonOverlay(paths, polygonOptions);
    if (polygonOverlay) {
      this.map.addOverlay(polygonOverlay);
    }
    return polygonOverlay;
  }

  drawBoundaryPolygons(activeBoundariesMapPolygons: mapkit.Overlay[], activeBoundariesGeojsonPolygons: GeoJsonPolygon[]): mapkit.Overlay[] {
    this.map.removeOverlays(activeBoundariesMapPolygons);

    // Display new boundary polygons on map
    const polygons = activeBoundariesGeojsonPolygons.map(geojsonPolygon => {
      const style = new mapkit.Style({
        strokeOpacity: 0,
        fillColor: '#043C7E',
        fillOpacity: 0.2,
      });
      const polygonOptions = { style };
      const polygon = new mapkit.PolygonOverlay(mapkitBoundaryFromGeojsonPolygon(geojsonPolygon) as unknown as mapkit.Coordinate[], polygonOptions);
      return this.map.addOverlay(polygon);
    });
    return polygons;
  }

  removePins(pins: mapkit.Annotation[]) {
    pins.forEach((pin: mapkit.Annotation) => this.removePin(pin));
  }

  removePin(pin: mapkit.Annotation): void {
    if (this.map.annotations.includes(pin)) {
      this.map.removeAnnotation(pin);
    }
  }

  pinPositionInPixels(latitude: number, longitude: number) {
    const coordinate = new mapkit.Coordinate(latitude, longitude);
    return this.map.convertCoordinateToPointOnPage(coordinate);
  }

  changeLocation(location: Position, zoom: number) {
    const center = mapkitPositionFromGeojsonPoint({
      type: 'Point',
      coordinates: [location.lng, location.lat],
    });
    if (!center) {
      return;
    }
    const zoomDiff = this.zoom - zoom;
    if (zoomDiff == 0) {
      this.map.setCenterAnimated(center);
    } else if (zoomDiff < 0) {
      this.zoomToRegion('in', zoomDiff, center);
    } else {
      this.zoomToRegion('out', zoomDiff, center);
    }
  }

  calculateTarget(pinPos: PinPos, _: unknown, xOffset: number, yOffset: number): PopupPosition {
    const leftOffset = parseInt(getComputedStyle(this.mapElement, null).left, 10);
    const topOffset = parseInt(getComputedStyle(this.mapElement, null).top, 10);
    return {
      x: pinPos.x - leftOffset - xOffset,
      y: pinPos.y - topOffset - yOffset,
      size: 20,
    };
  }

  travelMode(mode: string) {
    return mode === 'driving' ? mapkit.Directions.Transport.Automobile : mapkit.Directions.Transport.Walking;
  }

  directions(origin: string, destination: string, mode: string) {
    const directions = new mapkit.Directions();
    const options = {
      origin: origin,
      destination: destination,
      transportType: this.travelMode(mode),
    };
    return new Promise((resolve, reject) => {
      directions.route(options, (err, data) => {
        if (err || data.routes.length == 0) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  travelDuration(directions: mapkit.DirectionsResponse): string {
    return this.secondsFormatter(directions.routes[0].expectedTravelTime);
  }

  travelDistance(directions: mapkit.DirectionsResponse): string {
    return (directions.routes[0].distance / 1000) + 'km';
  }

  drawOverlays(directions: mapkit.DirectionsResponse): void {
    if (this.lineOverlay) {
      this.removeOverlay(this.lineOverlay);
    }
    const styles = {
      lineWidth: 6,
      strokeOpacity: 0.7,
      strokeColor: '#0074D9',
    };
    this.lineOverlay = this.getLineOverlay(directions, styles);
    this.map.addOverlay(this.lineOverlay);
    this.map.visibleMapRect = this.overlayBoundingRect(this.lineOverlay as PolylineOverlay);
    this.setRouteMarkers(this.lineOverlay);
  }

  placeImageMarker(icon: string, position: Position): void {
    const marker = this.imageMarker(icon, position);
    this.map.addAnnotation(marker);
  }

  clearMarkers(): void {
    this.map.annotations = [];
  }

  get mapElement() {
    const element = document.getElementById(this.mapElementId);
    if (!element) {
      const div = document.createElement('div');
      div.id = this.mapElementId;
      return div;
    }
    return element;
  }

  get travelTabs() {
    return [
      { label: 'driving', icon: 'icon-cab' },
      { label: 'walking', icon: 'icon-walk' },
    ];
  }

  get travelData() {
    return {
      driving: null,
      walking: null,
    };
  }

  get boundary(): GeoJsonPolygon {
    return geojsonPolygonFromMapkitBbox(this.map.visibleMapRect.toCoordinateRegion());
  }

  get latitude(): number {
    return this.map ? this.map.center.latitude : 0;
  }

  get longitude(): number {
    return this.map ? this.map.center.longitude : 0;
  }

  get zoom(): number {
    return this.map ? this.map._impl.zoomLevel : 0;
  }

  get annotations(): mapkit.Annotation[] {
    return this.map.annotations;
  }

  // ----- Private Functions -----
  calculateZoomRegion(zoomType: ZoomType): mapkit.CoordinateRegion {
    const { latitudeDelta, longitudeDelta } = this.map.region.span;
    const latDelta = zoomType == 'in' ? latitudeDelta / 2.1 : latitudeDelta * 1.9;
    const lngDelta = zoomType == 'in' ? longitudeDelta / 2.1 : longitudeDelta * 1.9;
    const regionSpan = new mapkit.CoordinateSpan(latDelta, lngDelta);
    const coordRegion = new mapkit.CoordinateRegion(this.map.center, regionSpan);
    return coordRegion;
  }

  setRemoveEventListenerCallbacks(mappable: MapkitMappable, showHover: () => void, removeHover: () => void): void {
    mappable.removeMouseEnterListener = function() {
      mappable.pinElement.removeEventListener('mouseenter', showHover);
    };
    mappable.removeClickListener = function() {
      mappable.pinElement.removeEventListener('touchend', showHover);
    };
    mappable.removeMouseLeaveListener = function() {
      mappable.pinElement.removeEventListener('mouseleave', removeHover);
    };
  }

  setMapRemoveEventListenersCallbacks(zoomListener: () => void, idleListener: () => void): void {
    this.removeZoomListener = () => {
      this.map.removeEventListener('zoom-end', zoomListener);
    };
    this.removeIdleListener = () => {
      this.map.removeEventListener('region-change-end', idleListener);
    };
  }

  zoomToRegion(zoomType: ZoomType, zoomDiff: number, center: mapkit.Coordinate): void {
    const latDeltaMultiplier = Math.pow(2.1, Math.abs(zoomDiff));
    const lngDeltaMultiplier = Math.pow(1.9, Math.abs(zoomDiff));
    const { latitudeDelta, longitudeDelta } = this.map.region.span;

    const latDelta = zoomType == 'in' ? latitudeDelta / latDeltaMultiplier: latitudeDelta * lngDeltaMultiplier;
    const lngDelta = zoomType == 'in' ? longitudeDelta / latDeltaMultiplier : longitudeDelta * lngDeltaMultiplier;
    const span = new mapkit.CoordinateSpan(latDelta, lngDelta);

    const region = new mapkit.CoordinateRegion(center, span);
    this.map.setRegionAnimated(region);
  }

  imageMarker(icon: string, position: Position): mapkit.ImageAnnotation {
    const pinOptions = {
      url: {
        1: icon,
      },
    };
    const coordinate = new mapkit.Coordinate(position.lat, position.lng);
    return new mapkit.ImageAnnotation(coordinate, pinOptions);
  }

  // lineOverlay takes an optional styles object to set its styling
  // the following properties are valid:
  // strokeColor, strokeOpacity, lineWidth, lineCap, lineJoin, lineDash, lineDashOffset,
  // fillColor, fillOpacity, fillRule
  getLineOverlay(directions: mapkit.DirectionsResponse, styles: OverlayStyles): mapkit.PolylineOverlay {
    const line = directions.routes[0].polyline;
    Object.assign(line.style, styles);
    return line;
  }

  overlayBoundingRect(overlay: PolylineOverlay) {
    return overlay._impl._boundingRect;
  }

  secondsFormatter(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - (hours * 3600)) / 60);

    return `${this.pluralizeTime(hours, 'Hour')} ${this.pluralizeTime(minutes, 'Minute')}`;
  }

  pluralizeTime(time: number, unit: string): string {
    if (time === 0) {
      return '';
    } else if (time === 1) {
      return `${time} ${unit}`;
    } else {
      return `${time} ${unit}s`;
    }
  }

  marker(options: MarkerOptions): mapkit.MarkerAnnotation {
    const origin = new mapkit.Coordinate(options.lat, options.lng);
    return new mapkit.MarkerAnnotation(origin, {
      color: options.color,
      glyphText: options.text,
    });
  }

  setRouteMarkers(lineOverlay: mapkit.PolylineOverlay): void {
    const origin = this.marker({
      lat: lineOverlay.points[0].latitude,
      lng:  lineOverlay.points[0].longitude,
      color: '#2ECC40',
      text: 'A',
    });

    const destination = this.marker({
      lat: lineOverlay.points[lineOverlay.points.length-1].latitude,
      lng:  lineOverlay.points[lineOverlay.points.length-1].longitude,
      color: '#FF4136',
      text: 'B',
    });
    this.map.annotations = this.map.annotations.concat([origin, destination]);
  }

  getHoverPosition(latitude: number, longitude: number, popup: HTMLElement, offsets: Offsets) {
    const {
      xOffset,
      yOffset,
      framePadding,
      frameOffset,
      targetPadding,
    } = offsets;
    const position = this.pinPositionInPixels(latitude, longitude);
    const target = this.calculateTarget(position, null, xOffset, yOffset);
    const frame = {
      width: this.mapElement.offsetWidth,
      height: this.mapElement.offsetHeight,
      padding: framePadding,
    };
    const element = {
      width: popup.offsetWidth,
      height: popup.offsetHeight,
    };

    return calculatePoint({
      frame,
      element,
      target,
      frameOffset,
      targetPadding,
    });
  }
}
