import { ThemeConfig } from './themeConfig';
import { translate } from '../utils/translations';
import { SiteLanguage } from 'constants/locale';

export const EXP_MENU_LINK_LABEL_SIGN_IN = 'Sign In' as const;
export const EXP_MENU_LINK_LABEL_MY_SEARCHES = 'My Searches' as const;

const ExprealtyCaTheme: ThemeConfig = {
  name: 'eXp Realty',
  title: 'eXp Realty - eXp Realty® Canada',
  faviconPath: 'favicon-exp.ico',
  metaDescription: "Find real estate listings for sale in Canada. Search MLS real estate listings for homes, condos and properties on exprealty.ca, Canada's top real estate website.",
  largeDesktopHeaderImage: '/next/assets/images/header-image-large-desktop-exp-ca.jpg',
  desktopHeaderImage: '/next/assets/images/header-image-desktop-exp-ca.jpg',
  mobileHeaderImage: '/next/assets/images/header-image-mobile-exp-ca.jpg',
  smallMobileImage: '/next/assets/images/header-image-small-mobile-exp-ca.jpg',
  ogSiteName: 'eXp Realty® Canada',
  ogImage: 'https://exprealty.ca/next/assets/images/exp-banner.png',
  schemaUrl: 'https://exprealty.ca',
  schemaLogo: 'https://exprealty.ca/next/assets/images/eXp-Realty-logo.png',
  schemaLocation: {
    addressCountry: 'United States',
    addressLocality: 'Bellingham',
    addressRegion: 'WA',
    postalCode: '98226',
    streetAddress: '2219 Rimland Drive Suite 301',
  },
  schemaFoundingDate: '2002',
  schemaFounders: [
    {
      '@type': 'Person',
      name: 'Sanford Glenn Darrel',
    },
    {
      '@type': 'Person',
      name: 'Brian Culhane',
    },
  ],
  colors: {
    primaryVeryLight: '#8ECFFF',
    primaryLight:'#E8EDF5',
    primary: '#0C0F24',
    primaryDark: '#0C0F24',
    primaryVeryDark: '#0C0F24',
    primaryBackground: '#FFF',
    primaryForSale: '#FFF',
    primarySold: '#6D071A',
    primaryFilters: '#0C0F24',
    primaryFontColor: '#0C0F24',
    primaryLinkColor: '#1B489B',
    primaryInvertedBackground: '#FFFFFF',
    primaryBorderColor: '#5A5A5A',
    primaryChart: '#F3F3F4',
    secondaryBackground: '#FFF',
    secondaryBorderColor: '#5A5A5A',
    secondaryLinkColor: '#3D3F50',
    tertiaryBackground: '#EFEFEF',
    tertiaryBorderColor: '#5A5A5A',
    tertiaryLinkColor: '#1B489B',
    quaternaryLinkColor: '#496DAF',
    transparentBackground: 'rgba(255, 255, 255, 0.1)',
    mapPinForSale: '#1B489B',
  },
  fonts: {
    heading: 'Manrope',
    text: 'Manrope',
    headingWeight: '300',
    headingBoldWeight: '500',
  },
  decorations: {
    brushstrokes: false,
    boxShadow: '0px 2px 4px 0px #00000014;',
    boxShadowLight: '0 0 8px 0 rgba(0, 0, 0, .1);',
    areaPageLineWidth: '51px',
  },
  buttons: {
    borderRadius: '100px',
    borderRadiusSmall: '8px',
    borderRadiusMedium: '8px',
    borderRadiusLarge: '100px',
  },
  icons: {
    borderRadius: '100px',
  },
  organization: {
    phoneNumber() {
      return '1 844-539-0722';
    },
    email() {
      return 'info@exprealty.net';
    },
    address() {
      return '2219 Rimland Drive, Suite 301,\nBellingham, Washington, 98226';
    },
    twitterUrl: 'https://twitter.com/exprealtycanada',
    facebookUrl: 'https://www.facebook.com/exprealtycanada',
    instagramUrl: 'https://www.instagram.com/expcanada_?igsh=YmtqZzRqZHR1Z2Yw&utm_source=qr',
    linkedinUrl: 'https://www.linkedin.com/showcase/exprealtycanada',
    youtubeUrl: 'https://www.youtube.com/@eXpRealty',
  },
  menuLinks: [
    {
      label: EXP_MENU_LINK_LABEL_MY_SEARCHES,
      link: '/account/saved/searches',
    },
    {
      label: 'Search',
      link: '/search',
    },
    {
      label: 'Buy',
      link: '/buy',
    },
    {
      label: 'Sell',
      link: '/sell',
    },
    {
      label: 'Agents',
      link: '/agents-search',
    },
    {
      label: 'About',
      link: '/explore-exp',
    },
    {
      label: 'Join eXp',
      link: '/join-exp',
    },
    {
      label: 'Sitemap',
      link: '/sitemap',
    },
  ],
  searchOptions: [
    {
      type: 'Buy',
      placeholder(siteLocation, isOnMobile) {
        const neighbourhood = translate('neighbourhood', siteLocation);
        return isOnMobile ? `City, ${neighbourhood}, or address` : `Enter a city, ${neighbourhood}, address, MLS® number or school`;
      },
    },
    {
      type: 'Rent',
      placeholder(siteLocation, isOnMobile) {
        const neighbourhood = translate('neighbourhood', siteLocation);
        return isOnMobile ? `City, ${neighbourhood}, or address` : `Enter a city, ${neighbourhood}, address, MLS® number or school`;
      },
    },
    {
      type: 'Sold',
      placeholder(siteLocation, isOnMobile) {
        const neighbourhood = translate('neighbourhood', siteLocation);
        return isOnMobile ? `City, ${neighbourhood}, or address` : `Enter a city, ${neighbourhood}, address, MLS® number or school`;
      },
    },
    {
      type: 'Agents',
      placeholder(siteLocation) {
        const neighbourhood = translate('neighbourhood', siteLocation);
        return `Enter a city or ${neighbourhood}`;
      },
    },
    {
      type: 'HomeAppraisal',
      placeholder() {
        return 'Enter your home address';
      },
    },
  ],
  contentBlockBuy: {
    title: 'Buying your next home',
    copy: 'eXp Realty transforms home buying into a seamless experience, tailored to your needs with cutting-edge technology and expert support. Discover a better way to buy a home with eXp.',
    image: '/next/assets/images/content-block/exp-buy.jpg',
    link: '/buy',
  },
  contentBlockSell: {
    title: 'Selling your home',
    copy: 'Discover a smarter selling experience with eXp Realty with a simplified process that blends advanced technology and market insight to sell your home effectively and efficiently.',
    image: '/next/assets/images/content-block/exp-sell.jpg',
    link: '/sell',
  },
  privacyPolicy: (language?: SiteLanguage) => {
    switch (language) {
    case SiteLanguage.French:
      return 'https://expworldholdings.com/privacy-policy-french/';
    default:
      return 'https://expworldholdings.com/privacy-policy/';
    }
  },
  termsOfUse: (language?: SiteLanguage) => {
    switch (language) {
    case SiteLanguage.French:
      return 'https://expworldholdings.com/terms-of-service-french/';
    default:
      return 'https://expworldholdings.com/terms-of-service/';
    }
  },
};

export default ExprealtyCaTheme;
