import { USER_LOCATION_COOKIE_NAME } from 'constants/cookies';
import { IncomingMessage } from 'http';
import { getObjectFromRequestCookie } from './cookies';
import { defaultCACityPayload, defaultUSCityPayload } from 'constants/locations';
import { IUserLocation } from 'components/home-page/location';
import { CountryCodeList } from 'types/countries';
import { FeaturesType } from 'contexts';
import { getFeaturesOverrideFromRequest } from './features';

/**
 * Retrieves the user location based on the incoming request and site location.
 * 
 * The function determines the user location through the following steps:
 * 1. Attempts to get the location from the user location cookie.
 * 2. Checks feature overrides from the request.
 * 3. Compares the user location country code with the site location.
 * 
 * The function applies specific rules based on the detected conditions:
 * - If feature overrides indicate using the US location, sets the user location to the default US city payload.
 * - If the user location country code does not match the site location, returns the default city payload based on the site location.
 * 
 * If no user location is determined through these steps, defaults to the city payload based on the site location.
 * 
 * @param request The incoming HTTP request object.
 * @param siteLocation The site location country code.
 * @returns The user location payload.
 */
export function getUserLocationFromRequest(request?: IncomingMessage, siteLocation = CountryCodeList.CANADA) {
  const isSiteUs = siteLocation === CountryCodeList.UNITED_STATES;
  let userLocation: IUserLocation | undefined;

  if (request) {
    userLocation = getObjectFromRequestCookie<IUserLocation>(USER_LOCATION_COOKIE_NAME, request);
    const features = getFeaturesOverrideFromRequest(request) as FeaturesType;
    if (features.useUsLocation) {
      userLocation = defaultUSCityPayload;
    } else if (userLocation && userLocation?.countryCode !== siteLocation) {
      userLocation = isSiteUs ? defaultUSCityPayload : defaultCACityPayload;
    }
  }
  
  return userLocation || (isSiteUs ? defaultUSCityPayload : defaultCACityPayload);
}