import { useState } from 'react';
import { useThemeContext } from 'contexts/theme';
import ZoocasaFooter from './zoocasaFooter';
import ExpRealtyCaFooter from './expRealtyCaFooter';
import { ThemeNames } from 'types/themes';
import LoadWhenVisible from 'components/load-when-visible';
import LoadingSkeleton from 'components/loading-skeleton';

export default function ThemedFooter() {
  const { themeName } = useThemeContext();
  const [isVisible, setIsVisible] = useState(false);

  switch (themeName) {
  case ThemeNames.EXP_REALTY_CA:
  case ThemeNames.EXP_REALTY_US:
    return <LoadWhenVisible onValueChange={() => setIsVisible(true)}>
      {!isVisible ? <LoadingSkeleton height="15.7em" />: <ExpRealtyCaFooter />}
    </LoadWhenVisible>;
  default:
    return <LoadWhenVisible onValueChange={() => setIsVisible(true)}>
      {!isVisible ? <LoadingSkeleton height="15.7em" />: <ZoocasaFooter />}
    </LoadWhenVisible>;
  }
}
