import Cookies from 'js-cookie';
import { AreaPageListing_Provider } from '@zoocasa/go-search';
import { User } from 'contexts';
import { ACCEPTED_TERMS_COOKIE_NAME, ACCEPTED_TERMS_EXPIRE_DAYS, ZOOCASA_TERMS_READ_COOKIE } from 'constants/cookies';

export const VOW_PROVIDERS = [
  AreaPageListing_Provider.NsarVow,
  AreaPageListing_Provider.TrebVow,
  AreaPageListing_Provider.OrebVow,
  AreaPageListing_Provider.ItsoVow,
  AreaPageListing_Provider.Pillar9Vow,
  AreaPageListing_Provider.RebgvVow,
];

export const BROWSER_BASED_TERMS = [ // These terms will not associate with a user
  AreaPageListing_Provider.CreaDdf,
];
export const BROWSER_BASED_TERMS_USER_ID = 'browser_based_terms';

interface AcceptedTermsCookie {
  userId: string;
  acceptedTerms: number[];
}

const getAcceptedTermsCookie = () => {
  const cookieValue = Cookies.get(ACCEPTED_TERMS_COOKIE_NAME);
  return cookieValue ? JSON.parse(cookieValue) as AcceptedTermsCookie[] : [];
};
const setAcceptedTermsCookie = (value: AcceptedTermsCookie[]) => {
  Cookies.set(ACCEPTED_TERMS_COOKIE_NAME, JSON.stringify(value), { expires: ACCEPTED_TERMS_EXPIRE_DAYS });
};

const acceptTerms = (providerId: AreaPageListing_Provider, userId?: User['id']) => {
  let userIdToSave = userId;
  if (BROWSER_BASED_TERMS.includes(providerId)) { // Put all providers that don't need to associate with a user as BROWSER_BASED_TERMS_USER_ID
    userIdToSave = BROWSER_BASED_TERMS_USER_ID;
  }
  if (userIdToSave) {
    const acceptedTerms = getAcceptedTermsCookie();
    const userIndex = acceptedTerms.findIndex(({ userId }) => userId === userIdToSave);
    if (userIndex !== -1) { // Existing user: add new providerId only
      acceptedTerms[userIndex].acceptedTerms.push(providerId);
    } else { // New user: add new userId with new providerId
      acceptedTerms.push({ userId: userIdToSave.toString(), acceptedTerms: [providerId]});
    }
    setAcceptedTermsCookie(acceptedTerms);
  }
};

const getAllAcceptedTermsByUser = (userId?: string | number) => {
  let browserBasedTerms:AreaPageListing_Provider[] = [];
  let userBasedTerms:AreaPageListing_Provider[] = [];
  const browserBasedTermsExists = getAcceptedTermsCookie().find((user: { userId: string | number }) => user.userId === BROWSER_BASED_TERMS_USER_ID);
  if (browserBasedTermsExists) {
    browserBasedTerms = browserBasedTermsExists.acceptedTerms;
  }
  if (userId) {
    const userExists = getAcceptedTermsCookie().find((user: { userId: string | number }) => user.userId === userId);
    if (userExists) {
      userBasedTerms = userExists.acceptedTerms;
    }
  }
  return {
    userBasedTerms,
    browserBasedTerms,
  };
};

const checkTermsAccepted = (providerId: AreaPageListing_Provider, userId?: User['id'] | null) => {
  if (BROWSER_BASED_TERMS.includes(providerId)) {
    const acceptedTerms = getAllAcceptedTermsByUser(BROWSER_BASED_TERMS_USER_ID);
    return acceptedTerms.browserBasedTerms.includes(providerId);
  } else if (userId) {
    const acceptedTerms = getAllAcceptedTermsByUser(userId);
    return acceptedTerms.userBasedTerms.includes(providerId);
  } else {
    return false;
  }
};

const isListingVisibleToUser = (
  isPrivate: boolean,
  providerId: number,
  acceptedBrowserBasedTerms: AreaPageListing_Provider[],
  acceptedTerms: AreaPageListing_Provider[],
  isSignInAndTermsOfUseRequired: boolean
) => {
  if (isPrivate) {
    return false;
  } else if (!isSignInAndTermsOfUseRequired) {
    return true;
  } else if (BROWSER_BASED_TERMS.includes(providerId)) {
    return acceptedBrowserBasedTerms.includes(providerId);
  } else if (providerId === AreaPageListing_Provider.TrebVow) {
    return true; // We currently don't need terms of use for TrebVow
  } else if (VOW_PROVIDERS.includes(providerId)) {
    return acceptedTerms.includes(providerId);
  } else {
    return true;
  }
};

const setZoocasaTermsAreReadInCookies = () => {
  Cookies.set(ZOOCASA_TERMS_READ_COOKIE, 'true', { expires: ACCEPTED_TERMS_EXPIRE_DAYS });
};

const checkZoocasaTermsAreReadInCookies = () => {
  return 'true' === Cookies.get(ZOOCASA_TERMS_READ_COOKIE);
};

export {
  acceptTerms as acceptTermsInCookies,
  getAllAcceptedTermsByUser,
  checkTermsAccepted,
  isListingVisibleToUser,
  setZoocasaTermsAreReadInCookies,
  checkZoocasaTermsAreReadInCookies,
};
